import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from "react-icons/md";
import { FormTextArea, Typography, Button, FormLabel } from "../../components";
import useThreads from "../../hooks/useThreads";
import useSendReply from "../../hooks/useSendReply";
import { useMutation } from "react-query";
import { AppContext } from "../../AppContext";
import moment from "moment";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h2>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default ({ open, close, messageId, refetch }) => {
  const [replyMessage, setReplyMessage] = React.useState("");
  const [messageList, setMessageList] = React.useState([]);

  const { currentUser } = React.useContext(AppContext);

  const { isLoading, data } = useThreads(messageId);

  console.warn(messageId, data);

  const [mutateSendReply, { isLoading: isLoadingSendReply }] = useMutation(
    useSendReply,
    {
      onSuccess: () => {
        refetch();
        setMessageList([
          {
            id: new Date().getTime(),
            message: replyMessage,
            sender_name: `${currentUser.first_name} ${currentUser.last_name}`,
          },
          ...messageList,
        ]);
        setReplyMessage("");
      },
      onError: (error) => console.log(11, error),
    }
  );

  useEffect(() => {
    if (data) {
      setMessageList(data.messages);
    }
  }, [data]);

  const handleChangeReply = (e) => setReplyMessage(e.target.value);

  const handleSend = (e) => {
    e.preventDefault();
    mutateSendReply({
      message_id: messageId,
      message: replyMessage,
    });
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title" onClose={close}>
        Threads
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ width: 800 }}>
          <div style={{ height: "50vh", overflow: "scroll" }}>
            {isLoading ? (
              <Typography h3>Loading</Typography>
            ) : (
              messageList.map(({ id, message, sender_name, created_at }) => (
                <div
                  key={id}
                  style={{
                    padding: "12px 0",
                    borderBottom: "1px solid #efefef",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography body1Bold>{sender_name}</Typography>
                    <Typography body2>
                      {moment(created_at).format("MMMM DD, YYYY")}
                    </Typography>
                  </div>
                  <Typography body2>{message}</Typography>
                </div>
              ))
            )}
          </div>
          <div style={{ borderTop: "1px solid #efefef", paddingTop: 10 }}>
            <FormLabel>Reply</FormLabel>
            <FormTextArea
              rows={4}
              value={replyMessage}
              onChange={handleChangeReply}
            />
            <Button
              type="submit"
              marginTop={10}
              disabled={!replyMessage}
              onClick={handleSend}
              isLoading={isLoadingSendReply}
            >
              Send
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
