import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableActionIcon,
  ViewDetailDialog,
  LoadMore,
  Confirm,
} from "../../components";
import colors from "../../constants/colors";
import { Tabs, Tab } from "./favouriteElements";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { MdContactPhone } from "react-icons/md";
import { useMutation } from "react-query";
import useMyFavorite from "../../hooks/useMyFavorite";
import useFavoriteReceived from "../../hooks/useFavoriteReceived";
import useDeleteFavorite from "../../hooks/useDeleteFavorite";
import Tooltip from "@material-ui/core/Tooltip";
import ContactDialog from "../listing/contactDialog";

export default () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [currentPageMyFav, setCurrentPageMyFav] = React.useState(1);
  const [currentPageFavReceived, setCurrentPageFavReceived] = React.useState(1);

  const [myFavList, setMyFavList] = React.useState([]);
  const [favReceivedList, setFavReceivedList] = React.useState([]);

  const [isViewDetailDialog, setIsViewDetailDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState([]);

  const [selectedFavourite, setSelectedFavourite] = React.useState(null);
  const [isRemoveFavouriteOpen, setIsRemoveFavouriteOpen] = React.useState(
    null
  );

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState(null);

  const {
    isLoading: isLoadingMyFavorite,
    data: myFavoriteData,
    isSuccess: isSuccessMyFavorite,
  } = useMyFavorite(currentPageMyFav);
  const {
    isLoading: isLoadingFavoriteReceived,
    data: favoriteReceivedData,
    isSuccess: isSuccessFavoriteReceived,
  } = useFavoriteReceived(currentPageFavReceived);

  const [mutateFavorite] = useMutation(useDeleteFavorite, {
    onSuccess: () => {},
  });

  React.useEffect(() => {
    if (myFavoriteData && currentPageMyFav === 1) {
      setMyFavList(myFavoriteData.my_favourite);
    } else if (myFavoriteData) {
      setMyFavList([...myFavList, ...myFavoriteData.my_favourite]);
    }
  }, [myFavoriteData]);

  React.useEffect(() => {
    if (favoriteReceivedData && currentPageFavReceived === 1) {
      setFavReceivedList(favoriteReceivedData.favourites_received);
    } else if (favoriteReceivedData) {
      setFavReceivedList([
        ...favReceivedList,
        ...favoriteReceivedData.favourites_received,
      ]);
    }
  }, [favoriteReceivedData]);

  if (
    (isLoadingMyFavorite && myFavList.length === 0) ||
    (isLoadingFavoriteReceived && favReceivedList.length === 0)
  ) {
    return <Typography body1>Loading...</Typography>;
  }

  const handleRemove = () => {
    setIsRemoveFavouriteOpen(false);
    setMyFavList(myFavList.filter((d) => d.id !== selectedFavourite));
    mutateFavorite({
      favourited_user_id: selectedFavourite,
    });
  };

  const handleViewDetail = ({
    first_name,
    last_name,
    phone_no,
    email,
    description,
    address,
    city,
    company,
  }) => {
    setSelectedUser([
      {
        label: "Name",
        value: `${first_name} ${last_name}`,
      },
      {
        label: "Phone number",
        value: phone_no,
      },
      {
        label: "Email",
        value: email,
      },
      {
        label: "Address",
        value: address,
      },
      {
        label: "City",
        value: city,
      },
      {
        label: "Company",
        value: company,
      },
      {
        label: "Description",
        value: description,
        newLine: true,
      },
    ]);
    setIsViewDetailDialog(true);
  };

  const handleLoadMoreFavReceived = () =>
    setCurrentPageFavReceived(currentPageFavReceived + 1);

  const handleLoadMoreMyFav = () => setCurrentPageMyFav(currentPageMyFav + 1);

  const dataPerPage = selectedTab === 0 ? myFavoriteData : favoriteReceivedData;
  const allData = selectedTab === 0 ? myFavList : favReceivedList;
  const isLoading =
    selectedTab === 0 ? isLoadingMyFavorite : isLoadingFavoriteReceived;
  const isSuccess =
    selectedTab === 0 ? isSuccessMyFavorite : isSuccessFavoriteReceived;
  const handleLoadMore =
    selectedTab === 0 ? handleLoadMoreMyFav : handleLoadMoreFavReceived;

  return (
    <>
      <ContactDialog
        id={selectedUserId}
        open={isDialogOpen}
        close={() => setIsDialogOpen(false)}
      />
      <ViewDetailDialog
        title="User Detail"
        open={isViewDetailDialog}
        close={() => setIsViewDetailDialog(false)}
        data={selectedUser}
      />
      <Confirm
        open={isRemoveFavouriteOpen}
        close={() => setIsRemoveFavouriteOpen(false)}
        confirmTitle="Remove"
        handleConfirm={handleRemove}
        title="Remove"
        description="Are you sure you want to remove?"
      />
      {/* <Typography h3>My Favorites</Typography> */}
      {/* <Typography body1>List of all favorites form the users..</Typography> */}
      <Tabs>
        <Tab selected={selectedTab === 0} onClick={() => setSelectedTab(0)}>
          <Typography
            body1Bold
            color={selectedTab === 0 ? colors.primary : "#494949"}
          >
            My Favorites
          </Typography>
        </Tab>
        <Tab selected={selectedTab === 1} onClick={() => setSelectedTab(1)}>
          <Typography
            body1Bold
            color={selectedTab === 1 ? colors.primary : "#494949"}
          >
            Favorites Received
          </Typography>
        </Tab>
      </Tabs>
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell size="small">S.N</TableCell> */}
            <TableCell>Name</TableCell>
            {/* <TableCell>Email Address</TableCell> */}
            <TableCell>Company</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allData.length === 0 ? (
            <div style={{ paddingTop: 15, paddingLeft: 8 }}>
              <Typography body1>
                {" "}
                {selectedTab === 0
                  ? "You do not have any favorite."
                  : "You have not received any favorite."}
              </Typography>
            </div>
          ) : null}
          {allData.map(
            ({ id, first_name, last_name, company, phone_no }, i) => (
              <TableRow key={id}>
                {/* <TableCell size="small">{i + 1}</TableCell> */}
                <TableCell>{`${first_name} ${last_name}`}</TableCell>
                {/* <TableCell>{email}</TableCell> */}
                <TableCell>{company}</TableCell>
                <TableCell>{phone_no}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Send Message" arrow>
                    <TableActionIcon
                      size={14}
                      color="#EBAC42"
                      onClick={() => {
                        setIsDialogOpen(true);
                        setSelectedUserId(id);
                      }}
                      // onClick={() =>
                      //   history.push({
                      //     pathname: "/profileDetail",
                      //     search: `?type=${parsedQuery.type}&id=${id}`,
                      //   })
                      // }
                    >
                      <MdContactPhone />
                    </TableActionIcon>
                  </Tooltip>
                  <Tooltip title="View Details" arrow>
                    <TableActionIcon
                      size={14}
                      color="#FC7722"
                      onClick={() => handleViewDetail(allData[i])}
                      // onClick={() =>
                      //   history.push({
                      //     pathname: "/profileDetail",
                      //     search: `?type=${parsedQuery.type}&id=${id}`,
                      //   })
                      // }
                    >
                      <FaEye />
                    </TableActionIcon>
                  </Tooltip>
                  {selectedTab === 0 && (
                    <Tooltip title="Remove" arrow>
                      <TableActionIcon
                        size={16}
                        color="#F52222"
                        onClick={() => {
                          setSelectedFavourite(id);
                          setIsRemoveFavouriteOpen(true);
                        }}
                      >
                        <IoMdCloseCircleOutline />
                      </TableActionIcon>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
        <LoadMore
          dataPerPage={dataPerPage}
          allData={allData}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClick={handleLoadMore}
        />
      </Table>
    </>
  );
};
