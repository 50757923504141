import React from "react";
import { Typography, Button } from "../../components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
// import Alert from "@material-ui/lab/Alert";
import { MdEdit, MdClose } from "react-icons/md";
import { ProfileAvatarEdit, PreviewPicture } from "./profileElements";
import IconButton from "@material-ui/core/IconButton";
import useChangeProfilePicture from "../../hooks/useChangeProfilePicture";
import { useMutation } from "react-query";
import { AppContext } from "../../AppContext";
import { setLocalStorageUser } from "../../utilities/setStorage";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h3>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default () => {
  const [open, setOpen] = React.useState(false);
  const [previewPicture, setPreviewPicture] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const fileRef = React.useRef(null);
  const { setCurrentUser } = React.useContext(AppContext);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [mutateChangeProfilePicture, { isLoading }] = useMutation(
    useChangeProfilePicture,
    {
      onSuccess: (response) => {
        const {
          data: {
            data: { profile },
            message,
          },
        } = response;
        setCurrentUser(profile);
        setLocalStorageUser(profile);
        setSuccessMessage(message);
      },
      onError: (error) => {
        if (error) {
          alert("Something went wrong during image upload.");
        }
      },
    }
  );

  React.useEffect(() => {
    if (successMessage) {
      handleClose();
    }
  }, [successMessage]);

  const handleClose = () => {
    setSuccessMessage("");
    setPreviewPicture(null);
    setFile(null);
    setOpen(false);
  };

  const handleChangeFile = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let selectedFile = e.target.files[0];

    reader.onloadend = () => {
      setFile(selectedFile);
      setPreviewPicture(reader.result);
      fileRef.current.value = "";
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      mutateChangeProfilePicture(formData);
    }
  };

  return (
    <>
      <ProfileAvatarEdit onClick={() => setOpen(true)}>
        <MdEdit />
      </ProfileAvatarEdit>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={false}
        onClose={handleClose}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Upload Profile Picture
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ width: 400 }}>
            <input
              type="file"
              id="avatar"
              name="avatar"
              accept="image/png, image/jpeg"
              onChange={handleChangeFile}
              ref={fileRef}
            />
            {previewPicture && <PreviewPicture src={previewPicture} />}
            {/* {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )} */}
            <Button marginTop={20} onClick={handleUpload} isLoading={isLoading}>
              Upload
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
