import React from "react";
import Typography from "../Typography/Typography";
import {
	HeroSection,
	WrapHero,
	HeroSectionContainer,
	DescriptionWrap,
	DescriptionWrap2,
	HorizontalBar,
	Span1,
	Em,
} from "./HeroSectionElements";

export default ({ title, description, description2, oftype }) => {
	return (
		<HeroSection>
			<WrapHero>
				<HeroSectionContainer
					style={{
						flexGrow: "1",
						padding: "1rem",
					}}>
					<Typography h1>{title}</Typography>
					<DescriptionWrap>
						<Typography body1 color="#fff">
							{description}
						</Typography>
					</DescriptionWrap>
					<DescriptionWrap>
						<Typography body1 color="#fff">
							{description2}
						</Typography>
					</DescriptionWrap>
				</HeroSectionContainer>

				{oftype === "Commercial" ? (
					<>
						<HorizontalBar></HorizontalBar>
						<HeroSectionContainer>
							<Typography body1>
								<Span1>CRE GUIDE</Span1>
							</Typography>
							<DescriptionWrap2>
								<Typography h3 color="#fff">
									Your guide to Commercial Real Estate
								</Typography>
								<Typography body1 color="#fff">
									<Em> Powered by Big Hound</Em>
								</Typography>
							</DescriptionWrap2>
						</HeroSectionContainer>
					</>
				) : null}
			</WrapHero>
		</HeroSection>
	);
};
