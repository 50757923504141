import Api from "../api/api";
import { useQuery } from "react-query";

const getPageContent = async (_, slug) => {
  const { data } = await Api.post("page", { slug: slug }, {});
  return data.data.page;
};

export default (props) => {
  return useQuery(["page-content", props], getPageContent);
};
