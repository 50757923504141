import React from "react";
import colors from "../../constants/colors";
import { Button, BtnIcon } from "./ButtonElements";
import ClipLoader from "react-spinners/ClipLoader";
import Typography from "../Typography/Typography";
export default ({
  children,
  icon,
  marginTop,
  white,
  round,
  isLoading,
  cancel,
  color,
  disabled,
  ...otherProps
}) => {
  let style = {
    marginTop: marginTop && marginTop,
    backgroundColor: white ? "#fff" : colors.primary,
    borderRadius: round ? 20 : 4,
  };

  if (cancel) {
    style = {
      ...style,
      backgroundColor: "#494949",
    };
  }

  if (color) {
    style = {
      ...style,
      backgroundColor: color,
    };
  }

  if (disabled) {
    style = {
      ...style,
      opacity: 0.7,
    };
  }

  return (
    <Button style={style} {...otherProps} disabled={disabled}>
      {isLoading ? (
        <ClipLoader size={28} color="#fff" loading={true} />
      ) : (
        <>
          {icon && <BtnIcon>{icon}</BtnIcon>}
          <Typography body2 color={white ? colors.primary : "#fff"}>
            {children}
          </Typography>
        </>
      )}
    </Button>
  );
};
