import Api from "../api/api";
import { useQuery } from "react-query";

const getAssociations = async (_, page) => {
  const { data } = await Api.get(`social?category=associations&page=${page}`);
  return data.data;
};

export default (props) => {
  return useQuery(["associations", props], getAssociations);
};
