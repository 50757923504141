import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableActionIcon,
  Confirm,
  LoadMore,
} from "../../../components";
import { HeaderWrap } from "./myLoanPostElements";
import { FaPen, FaEye } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import AddMyLoanPost from "./addMyLoanPost";
import useMyLoanPost from "../../../hooks/useMyLoanPost";
import useMyLoanPostDelete from "../../../hooks/useMyLoanPostDelete";
import { useMutation } from "react-query";
import Alert from "@material-ui/lab/Alert";
import EditMyLoanPost from "./editMyLoanPost";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();

  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [selectedLoanId, setSelectedLoanId] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(1);

  const { isLoading, data, refetch, isSuccess } = useMyLoanPost(currentPage);
  const [loanPostList, setLoanPostList] = React.useState([]);

  const [mutateMyLoanPostDelete] = useMutation(useMyLoanPostDelete, {
    onSuccess: (response) => {
      refetch();
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      setSuccessMessage(response.message);
    },
    onError: () => {
      alert("Something went wrong.");
      setIsDeleteOpen(false);
    },
  });

  React.useEffect(() => {
    if (currentPage === 1 && data) {
      setLoanPostList(data.loan_posts);
    } else if (data) {
      setLoanPostList([...loanPostList, ...data.loan_posts]);
    }
  }, [data]);

  if (isLoading && loanPostList.length === 0) {
    return <Typography h3>Loading...</Typography>;
  }

  const handleDelete = () => {
    setIsDeleteOpen(false);
    mutateMyLoanPostDelete(selectedLoanId);
  };

  return (
    <>
      <Confirm
        open={isDeleteOpen}
        close={() => setIsDeleteOpen(false)}
        confirmTitle="Delete"
        handleConfirm={handleDelete}
        title="Delete"
        description="Are you sure you want to delete?"
      />
      {isEditOpen && (
        <EditMyLoanPost
          open={isEditOpen}
          close={() => setIsEditOpen(false)}
          id={selectedLoanId}
          refetch={refetch}
        />
      )}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <HeaderWrap>
        <div style={{ marginBottom: 10 }}>
          <Typography h3>My Community Board</Typography>
          <Typography body1>List of all the Community Board.</Typography>
        </div>
        <AddMyLoanPost refetch={refetch} />
      </HeaderWrap>
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell size="small">S.N</TableCell> */}
            <TableCell>Title</TableCell>
            {/* <TableCell>Category</TableCell> */}
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loanPostList.map(({ id, title }) => (
            <TableRow key={id}>
              {/* <TableCell size="small">{loanIndex + 1}</TableCell> */}
              <TableCell>{title}</TableCell>
              {/* <TableCell>{category}</TableCell> */}
              <TableCell align="center">
                <Tooltip title="Edit" arrow>
                  <TableActionIcon
                    color="#60bff6"
                    onClick={() => {
                      setSelectedLoanId(id);
                      setIsEditOpen(true);
                    }}
                  >
                    <FaPen />
                  </TableActionIcon>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <TableActionIcon
                    size={16}
                    color="#F52222"
                    onClick={() => {
                      setSelectedLoanId(id);
                      setIsDeleteOpen(true);
                    }}
                  >
                    <IoMdCloseCircleOutline />
                  </TableActionIcon>
                </Tooltip>
                <Tooltip title="View Details" arrow>
                  <TableActionIcon
                    size={14}
                    color="#FC7722"
                    onClick={() => history.push(`/communityBoard/${id}`)}
                    // onClick={() => setIsViewDetailDialog(true)}
                  >
                    <FaEye />
                  </TableActionIcon>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <LoadMore
        dataPerPage={data}
        allData={loanPostList}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClick={() => setCurrentPage(currentPage + 1)}
      />
    </>
  );
};
