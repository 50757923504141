import Api from "../api/api";
import { useQuery } from "react-query";

const getTopNews = async () => {
  const { data } = await Api.get("top-news");
  return data.data.news;
};

export default () => {
  return useQuery("topNews", getTopNews);
};
