import Api from "../api/api";
import { useQuery } from "react-query";

const getFavoriteReceived = async (page) => {
  const { data } = await Api.get(`favorites-received?page=${page}`);
  return data.data;
};

export default (page) => {
  return useQuery(["favoriteReceived", page], () => getFavoriteReceived(page));
};
