import Api from "../api/api";
import { useQuery } from "react-query";

const getBasicProfile = async () => {
  const { data } = await Api.get("profile");
  return data.data.profile;
};

export default () => {
  return useQuery("basicProfile", getBasicProfile);
};
