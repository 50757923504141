import Api from "../api/api";
import { useQuery } from "react-query";

const getHotProperty = async (_, page) => {
  const { data } = await Api.get(`property?page=${page}`);
  return data.data;
};

export default (props) => {
  return useQuery(["hotProperty", props], getHotProperty);
};
