import React from "react";
import { Button, Typography, FormLabel, FormInput } from "../../../components";
import {
  InputRow,
  InputWrap,
  // CategoryBtnWrap,
  // CategoryBtn,
  // DescriptionTextArea,
} from "../profileElements";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { FaAngleDown } from "react-icons/fa";
import Checkbox from "@material-ui/core/Checkbox";
// import { MdCheckCircle } from "react-icons/md";
// import colors from "../../../constants/colors";
import useBusinessProfile from "../../../hooks/useBusinessProfile";
import useBusinessProfileUpdate from "../../../hooks/useBusinessProfileUpdate";
import useBusinessDescription from "../../../hooks/useBusinessDescription";
import ReactSelect from "react-select";
import { useMutation } from "react-query";
import Alert from "@material-ui/lab/Alert";
import Territory from "../residentialTerritory";
import getAmount from "../../../utilities/getAmount";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./editor.css";
import CategoryButton from "./categoryButton";

export default () => {
  const [successMessage, setSuccessMessage] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [subCategories, setSubCategories] = React.useState([]);
  const [businessDescription, setBusinessDescription] = React.useState("");

  const {
    isLoading: isLoadingBusinessProfile,
    data: businessProfileData,
    refetch,
  } = useBusinessProfile();

  const [
    mutateBusinessDescription,
    { isLoading: isLoadingBusinessDescription },
  ] = useMutation(useBusinessDescription, {
    onSuccess: (response) => {
      refetch();
      setSuccessMessage(response.message);
      // remove success message after 3 second
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    },
    onError: () => {
      alert("Something went wrong during business description update.");
    },
  });

  const [mutateBusinessProfile, { isLoading: isLoadingBusinessProfileUpdate }] =
    useMutation(useBusinessProfileUpdate, {
      onSuccess: (response) => {
        window.scrollTo(0, 0);
        refetch();
        setSuccessMessage(response.message);

        // remove success message after 3 second
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      },
      onError: () => {
        alert("Something went wrong during business profile update.");
      },
    });

  React.useEffect(() => {
    if (businessProfileData) {
      setBusinessDescription(
        businessProfileData.profile.description
          ? businessProfileData.profile.description
          : ""
      );

      setSelectedCategory(businessProfileData.categories[0].id);
      setSubCategories(businessProfileData.categories[0].subcategories);
    }
  }, [businessProfileData]);

  if (isLoadingBusinessProfile) {
    return <Typography body1>Loading...</Typography>;
  }

  const handleChangeSubCategory = (id) => {
    setSubCategories(
      subCategories.map((subCategory) => ({
        ...subCategory,
        selected:
          subCategory.id === id ? !subCategory.selected : subCategory.selected,
      }))
    );
  };

  const handleChange = (selectedItem, filter_id) => {
    setSubCategories(
      subCategories.map((subCategory) => ({
        ...subCategory,
        filters: subCategory.filters.map((filter) => ({
          ...filter,
          current_value:
            filter_id === filter.filter_id
              ? selectedItem
              : filter.current_value,
        })),
      }))
    );
  };

  const handleSaveBusinessProfile = () => {
    setSuccessMessage(false);
    mutateBusinessProfile({
      ...businessProfileData.categories.find((d) => d.id === selectedCategory),
      subcategories: subCategories.map((subCategory) => ({
        ...subCategory,
        filters: subCategory.selected
          ? subCategory.filters
          : subCategory.filters.map((filter) => ({
              ...filter,
              current_value: null,
            })),
      })),
    });
  };

  // const handleDescription = (e) => {
  //   setBusinessDescription(e.target.value);
  // };

  const handleSaveDescription = () => {
    mutateBusinessDescription({
      description: businessDescription,
    });
  };

  const { business_status, residential_status, commercial_status } =
    businessProfileData.profile;
  const status = [business_status, residential_status, commercial_status].find(
    (d) => d === "approved"
  );

  let selectedBusinessStatus;
  if (selectedCategory === 1) {
    selectedBusinessStatus = commercial_status;
  } else if (selectedCategory === 2) {
    selectedBusinessStatus = residential_status;
  } else if (selectedCategory === 3) {
    selectedBusinessStatus = business_status;
  }

  return (
    <>
      <Typography h3>Business Profile</Typography>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {status ? (
        <div>
          <Typography body1>Please complete your business profile.</Typography>
          <div style={{ paddingTop: 10, paddingBottom: 5 }}>
            <Typography body1Bold>Business Description</Typography>
          </div>
          {/* <DescriptionTextArea
          id="businessDescription"
          name="businessDescription"
          rows={6}
          defaultValue={businessDescription}
          onKeyUp={(e) => setBusinessDescription(e.target.value)}
        /> */}
          <CKEditor
            editor={ClassicEditor}
            data={businessDescription}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "undo",
                "redo",
                "numberedList",
                "bulletedList",
              ],
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setBusinessDescription(data);
            }}
          />
          <Button
            onClick={handleSaveDescription}
            isLoading={isLoadingBusinessDescription}
            marginTop={10}
          >
            Save Changes
          </Button>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#cce5ff",
            borderColor: "#b8daff",
            padding: "10px 14px",
            borderRadius: 2,
            marginTop: 5,
          }}
        >
          <Typography body1>
            Business description will be unlocked after your business profile
            request is approved.
          </Typography>
        </div>
      )}
      <CategoryButton
        categories={businessProfileData?.categories}
        profile={businessProfileData?.profile}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        setSubCategories={setSubCategories}
      />
      {selectedBusinessStatus === "pending" ? (
        <div style={{ paddingTop: 10 }}>
          <Typography body1>Your profile request has been sent.</Typography>
        </div>
      ) : (
        <>
          <div style={{ display: "flex", flexWrap: "wrap", padding: "10px 0" }}>
            {subCategories.map(({ id, title, selected }) => (
              <div key={id}>
                <Checkbox
                  checked={selected}
                  onChange={() => handleChangeSubCategory(id)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <span
                  style={{ fontSize: 14, color: "#494949", fontWeight: 300 }}
                >
                  {title}
                </span>
              </div>
            ))}
          </div>
          {selectedCategory && <Territory categoryId={selectedCategory} />}
          {subCategories.map(
            ({ id, title, selected, filters }) =>
              selected && (
                <Accordion key={id}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography body1Bold>{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexWrap: "wrap" }}>
                    <InputRow key={id}>
                      {filters.map(
                        ({
                          filter_id,
                          filter_label,
                          current_value,
                          filter_type,
                          filter_list,
                        }) => (
                          <InputWrap key={filter_id}>
                            <FormLabel>
                              {filter_label}
                              {filter_type === "percentage_input" && " (%)"}
                            </FormLabel>
                            {(filter_type === "input" ||
                              filter_type === "amount_input" ||
                              filter_type === "percentage_input") && (
                              <FormInput
                                dollarSign={filter_type === "amount_input"}
                                percentageSign={
                                  filter_type === "percentage_input"
                                }
                                value={
                                  filter_type === "amount_input"
                                    ? getAmount(current_value)
                                    : current_value
                                }
                                onChange={(e) => {
                                  let value = e.target.value;
                                  // remove comma
                                  if (filter_type === "amount_input") {
                                    value = value.replace(/,/g, "");
                                  }
                                  if (
                                    filter_type === "percentage_input" &&
                                    isNaN(value)
                                  ) {
                                    return;
                                  }
                                  handleChange(value, filter_id);
                                }}
                              />
                            )}
                            {(filter_type === "select" ||
                              filter_type === "multi_select") && (
                              <div style={{ width: "100%", marginTop: 5 }}>
                                <ReactSelect
                                  options={filter_list}
                                  onChange={(e) => handleChange(e, filter_id)}
                                  value={current_value}
                                  isMulti={filter_type === "multi_select"}
                                  isClearable={true}
                                />
                              </div>
                            )}
                          </InputWrap>
                        )
                      )}
                    </InputRow>
                  </AccordionDetails>
                </Accordion>
              )
          )}
          {selectedCategory && (
            <div>
              <Button
                onClick={handleSaveBusinessProfile}
                isLoading={isLoadingBusinessProfileUpdate}
                marginTop={15}
              >
                {selectedBusinessStatus === "not_requested"
                  ? "Send Request"
                  : "Save Changes"}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
