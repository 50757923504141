import styled from "styled-components";
import colors from "../../constants/colors";
import { md, sm } from "../../constants/layout";

export const BreadCrumbsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media screen and (max-width: ${md}) {
    flex-direction: column;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  width: 25%;
  flex-direction: column;
  background-color: #f0f8fc;
  margin-bottom: 10px;

  @media screen and (max-width: ${md}) {
    width: 100%;
    flex-direction: row;
  }
`;

export const ProfileWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px 10px;
`;

export const ProfileAvatarWrap = styled.div`
  margin-bottom: 15px;
  position: relative;
`;

export const ProfileAvatarEdit = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 15px;
  background-color: ${colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  right: -10px;
  top: 25%;
  cursor: pointer;
`;

export const ProfileAvatar = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 60px;
  object-fit: cover;

  @media screen and (max-width: ${md}) {
    width: 70px;
    height: 70px;
  }
`;

export const ProfileTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
  justify-content: center;
`;

export const MenuListWrap = styled.div`
  @media screen and (max-width: ${md}) {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    background-color: white;
  }
`;

export const LeftMenu = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  padding-left: 20px;
  background-color: ${({ selected }) => (selected ? colors.primary : "#fff")};
  cursor: pointer;
  border-bottom: ${({ border }) => (border ? "0.5px solid #f2f2f2" : "none")};

  @media screen and (max-width: ${md}) {
    width: 50%;
  }
  @media screen and (max-width: ${sm}) {
    width: 100%;
  }
`;

export const LeftMenuIcon = styled.div`
  margin-right: 10px;
  display: flex;
  color: ${({ selected }) => (selected ? "#fff" : "#494949")};
  font-size: 22px;
`;

export const RightColumn = styled.div`
  display: flex;
  width: 74%;
  flex-direction: column;
  background-color: #fff;
  padding: 28px 25px;

  @media screen and (max-width: ${md}) {
    width: 100%;
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const InputWrap = styled.div`
  width: 49%;
  margin: 5px 0;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const DescriptionTextArea = styled.textarea`
  width: 100%;
  border: none;
  background-color: #f2f2f2;
  padding: 10px 20px;
  font-weight: 300;
  margin-top: 5px;
`;

export const CategoryBtnWrap = styled.div`
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
`;

export const CategoryBtn = styled.button`
  display: flex;
  border: 1px solid ${colors.primary};
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: ${({ selected }) => (selected ? colors.primary : "#fff")};
  padding: 0 25px;
  margin-right: 15px;
  position: relative;
  margin-bottom: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
    opacity: 0.8;
  }
`;

export const PreviewPicture = styled.img`
  width: 140px;
  height: 140px;
  padding: 10px 0;
  object-fit: cover;
`;

export const DismissButton = styled.button`
  width: 100px;
  height: 40px;
  margin-right: 5px;
  border: 1px solid #ddd;
  color: #666;
  border-radius: 2px;
  margin-top: 15px;
  cursor: pointer;

  :hover {
    border: 1px solid #aaa;
  }
`;

export const SettingsBtn = styled.button`
  height: 40px;
  margin-right: 12px;
  border: 1px solid #ddd;
  color: ${({ red }) => (red ? "#721c24" : "#666")};
  border-radius: 2px;
  margin-top: 15px;
  cursor: pointer;
  padding: 0 16px;
  background-color: ${({ red }) => red && "#f8d7da"};

  :hover {
    border: 1px solid ${({ red }) => (red ? "#721c24" : "#aaa")};
  }
`;
