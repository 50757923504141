import Api from "../api/api";
import { useQuery } from "react-query";

const getCategories = async () => {
  const { data } = await Api.get("categories");
  return data.data.categories;
};

export default () => {
  return useQuery("categories", getCategories);
};
