import React from "react";
import {
  BreadCrumbs,
  Container,
  HeroSection,
  Typography,
  FormLabel,
  FormInput,
} from "../../components";
import {
  ColumnWrapper,
  LeftColumn,
  LeftColumnContent,
  RightColumn,
  FilterInputWrap,
  TagsInputWrap,
  Tag,
  FilterButton,
  FilterWrap,
} from "./listingElements";
import ProfileList from "./ProfileList";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useMutation } from "react-query";
import useHomeSearchList from "../../hooks/useHomeSearchList";
import FilterDialog from "./FilterDialog";
import { BsFilter } from "react-icons/bs";
import useCategories from "../../hooks/useCategories";

export default () => {
  const { search } = useLocation();
  const parsedQuery = queryString.parse(search, { parseNumbers: true });
  const history = useHistory();

  const { data: categoryList } = useCategories();

  const [searchText, setSearchText] = React.useState(parsedQuery.searchText);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [profileList, setProfileList] = React.useState([]);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  const [mutateSearchList, { isLoading, data, isSuccess }] = useMutation(
    useHomeSearchList,
    {
      onSuccess: (response) => {
        const {
          pagination: { current_page },
          profiles,
        } = response;
        setCurrentPage(current_page);

        if (current_page === 1) {
          setProfileList(profiles);
        } else {
          setProfileList([...profileList, ...profiles]);
        }
      },
      onError: () => {
        alert("Something went wrong.");
      },
    }
  );

  React.useEffect(() => {
    setSearchText(parsedQuery.searchText);
    setSelectedCategory(parsedQuery.type);
  }, []);

  React.useEffect(() => {
    if (categoryList) {
      fetchSearchList(1, parsedQuery.type, parsedQuery.searchText);
    }
  }, [categoryList]);

  const fetchSearchList = (
    page,
    selected_category = selectedCategory,
    search_key = searchText
  ) => {
    mutateSearchList({
      currentPage: page,
      body: {
        category_id: categoryList.find(
          (category) => category.title === selected_category
        )?.id,
        search_key,
      },
    });
  };

  const handleSearchText = (e) => {
    const { value } = e.target;
    setSearchText(value);

    setQueryString(undefined, value);
  };

  const handleFilter = () => fetchSearchList(1);

  const handleLoadMore = () => fetchSearchList(currentPage + 1);

  const getFilterList = () => {
    return (
      <>
        <FilterInputWrap>
          <FormLabel>You Searched For:</FormLabel>
          <FormInput
            value={searchText}
            placeholder="Search"
            onChange={handleSearchText}
          />
        </FilterInputWrap>
        {
          <FilterInputWrap>
            <FormLabel>Category</FormLabel>
            <TagsInputWrap>
              {categoryList &&
                categoryList.map(({ title }) => (
                  <Tag
                    key={title}
                    onClick={() => {
                      setQueryString(title);
                      setSelectedCategory(title);
                    }}
                    selected={selectedCategory === title}
                  >
                    <Typography
                      body2
                      color={selectedCategory === title ? "#fff" : "#9A9999"}
                    >
                      {title}
                    </Typography>
                  </Tag>
                ))}
            </TagsInputWrap>
          </FilterInputWrap>
        }
      </>
    );
  };

  const setQueryString = (query1 = selectedCategory, query2 = searchText) => {
    history.push({
      pathname: "/homeSearchList",
      search: `?type=${query1}&searchText=${query2}`,
    });
  };

  return (
    <>
      <HeroSection
        title={selectedCategory}
        // title={data?.title ? data.title : ""}
        description={`Search For ${selectedCategory}`}
      />
      <Container>
        <BreadCrumbs
          data={[
            {
              title: selectedCategory,
              pathname: "",
            },
          ]}
        />
        <ColumnWrapper>
          <FilterDialog
            title="Filter"
            open={isFilterOpen}
            close={() => setIsFilterOpen(false)}
          >
            <div style={{ backgroundColor: "white", width: 300 }}>
              {getFilterList()}
              <FilterButton
                onClick={() => {
                  setIsFilterOpen(false);
                  handleFilter();
                }}
              >
                <span>Apply Filter</span>
              </FilterButton>
            </div>
          </FilterDialog>
          <FilterWrap>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => setIsFilterOpen(true)}
            >
              <BsFilter size={26} style={{ marginRight: 10 }} />
              <Typography body1>Filter</Typography>
            </div>
          </FilterWrap>
          <LeftColumn>
            <LeftColumnContent>
              {getFilterList()}
              <FilterButton onClick={handleFilter}>
                <span>Apply Filter</span>
              </FilterButton>
            </LeftColumnContent>
          </LeftColumn>
          <RightColumn>
            <ProfileList
              isLoading={isLoading}
              isSuccess={isSuccess}
              dataPerPage={data}
              allData={profileList}
              refetch={() => fetchSearchList(1)}
              handleLoadMore={handleLoadMore}
              selectedCategory={selectedCategory}
            />
          </RightColumn>
        </ColumnWrapper>
      </Container>
    </>
  );
};
