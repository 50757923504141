import validator from "validator";

export default (values) => {
  let errors = {};

  values.forEach(({ name, value, label, required }) => {
    if (required) {
      if (!value) {
        errors[name] = `${label} is Required`;
      } else if (typeof value === "string" && !value.trim()) {
        errors[name] = `${label} is Required`;
      }
    }
  });

  // Email
  const emailAddress = values.find((d) => d.name === "emailAddress");
  if (
    emailAddress &&
    emailAddress.value.length &&
    !validator.isEmail(emailAddress.value)
  ) {
    errors.emailAddress = "Email address is invalid";
  }

  // Password
  const password = values.find((d) => d.name === "password");
  if (password && password.value && password.value.length < 6) {
    errors.password = "Password needs to be 6 characters or more";
  }

  const confirmPassword = values.find((d) => d.name === "confirmPassword");
  if (password && confirmPassword && password.value !== confirmPassword.value) {
    errors.confirmPassword = "Passwords do not match";
  }

  // // phone number
  // const phoneNumber = values.find((d) => d.name === "phoneNumber");
  // if (
  //   phoneNumber &&
  //   phoneNumber.value &&
  //   !validator.isMobilePhone(phoneNumber.value)
  // ) {
  //   errors.phoneNumber = "Invalid phone number";
  // }
  // phone number
  // const phoneNumber = values.find((d) => d.name === "phoneNumber");
  // if (phoneNumber && phoneNumber.value?.length !== 14) {
  //   errors.phoneNumber = "Invalid phone number";
  // }

  // website
  const website = values.find((d) => d.name === "website");
  if (website && website.value && !validator.isURL(website.value)) {
    errors.website = "Invalid website url";
  }

  return errors;
};
