import React from "react";
import {
  BreadCrumbs,
  Container,
  HeroSection,
  Typography,
  Confirm,
} from "../../components";
import {
  ColumnWrapper,
  LeftColumn,
  ProfileWrap,
  ProfileAvatarWrap,
  ProfileAvatar,
  LeftMenu,
  LeftMenuIcon,
  RightColumn,
  MenuListWrap,
} from "./profileElements";
import defaultProfilePicture from "../../assets/defaultProfilePicture.png";
import {
  MdBusinessCenter,
  MdPerson,
  MdAttachMoney,
  MdNotificationsNone,
} from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FiSettings } from "react-icons/fi";
import Basic from "./basic";
import Business from "./business/business";
import Favourite from "./favourite";
import Message from "./message";
import MyLoanPost from "./myLoanPost/myLoanPost";
import Settings from "./settings";
import { AppContext } from "../../AppContext";
import UploadProfilePicture from "./uploadProfilePicture";
import { useParams, useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import Notifications from "./notifications";
import useNotifications from "../../hooks/useNotifications";

const menuList = [
  {
    name: "Basic Profile",
    icon: <MdPerson />,
    profileType: "basic",
  },
  {
    name: "Business Profile",
    icon: <MdBusinessCenter />,
    profileType: "business",
  },
  {
    name: "My Favorites",
    icon: <FaRegHeart />,
    profileType: "favorite",
  },
  {
    name: "My Messages",
    icon: <IoMdMail />,
    profileType: "message",
  },
  {
    name: "Notifications",
    icon: <MdNotificationsNone />,
    profileType: "notifications",
  },
  {
    name: "My Community Board",
    icon: <MdAttachMoney />,
    profileType: "loan",
  },
  {
    name: "Settings",
    icon: <FiSettings />,
    profileType: "settings",
  },
];

export default () => {
  const { data, isSuccess } = useNotifications();
  const history = useHistory();
  const { profileType } = useParams();
  const [selectedMenu, setSelectedMenu] = React.useState(
    menuList.find((d) => d.profileType === profileType).name
  );
  const { currentUser, setCurrentUser } = React.useContext(AppContext);
  const [isLogoutOpen, setIsLogoutOpen] = React.useState(false);

  React.useEffect(() => {
    if (profileType) {
      setSelectedMenu(menuList.find((d) => d.profileType === profileType).name);
    }
  }, [profileType]);

  const handleMenuClick = (name, profileType) => {
    setSelectedMenu(name);
    history.push({
      pathname: profileType,
    });
  };

  const handleLogout = () => {
    setIsLogoutOpen(false);
    localStorage.clear();
    setCurrentUser(null);
    firebase.auth().signOut();
    history.push("/");
  };

  return (
    <>
      <HeroSection
        title="My Profile"
        // description="Raynor, Medhurst and Olson"
      />
      <Container>
        <Confirm
          open={isLogoutOpen}
          close={() => setIsLogoutOpen(false)}
          confirmTitle="Logout"
          handleConfirm={handleLogout}
          title="Logout"
          description="Are you sure you want to logout?"
        />
        <BreadCrumbs data={[{ title: "Profile" }]} />
        <ColumnWrapper>
          <LeftColumn>
            <ProfileWrap>
              <ProfileAvatarWrap>
                <UploadProfilePicture />
                <ProfileAvatar
                  src={
                    currentUser.image
                      ? currentUser.image
                      : defaultProfilePicture
                  }
                />
              </ProfileAvatarWrap>
              <Typography h3>
                {currentUser.first_name} {currentUser.last_name}
              </Typography>
              <div style={{ textAlign: "center" }}>
                <Typography body2>
                  {currentUser.job_title ? `${currentUser.job_title}, ` : null}{" "}
                </Typography>
                <Typography body2>{currentUser.company}</Typography>
              </div>
            </ProfileWrap>
            <MenuListWrap>
              {menuList.map(({ name, icon, profileType }, index) => (
                <LeftMenu
                  key={name}
                  selected={selectedMenu === name}
                  onClick={() => handleMenuClick(name, profileType)}
                  border={index !== 0 || index !== menuList.length - 1}
                >
                  <LeftMenuIcon selected={selectedMenu === name}>
                    {icon}
                  </LeftMenuIcon>
                  <Typography
                    body1Bold
                    color={selectedMenu === name ? "#fff" : undefined}
                  >
                    {name}
                  </Typography>
                  {isSuccess &&
                  data.notifications.length &&
                  profileType === "notifications" ? (
                    <div
                      style={{
                        backgroundColor:
                          selectedMenu === name ? "white" : "red",
                        color: selectedMenu === name ? "red" : "white",
                        borderRadius: 12,
                        width: 24,
                        height: 24,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 12,
                      }}
                    >
                      <span style={{ fontSize: 12, fontWeight: 500 }}>
                        {data.notifications.length}
                      </span>
                    </div>
                  ) : null}
                </LeftMenu>
              ))}
              <LeftMenu onClick={() => setIsLogoutOpen(true)}>
                <LeftMenuIcon>
                  <MdPerson />
                </LeftMenuIcon>
                <Typography body1>Logout</Typography>
              </LeftMenu>
            </MenuListWrap>
          </LeftColumn>
          <RightColumn>
            {selectedMenu === menuList[0].name && <Basic />}
            {selectedMenu === menuList[1].name && <Business />}
            {selectedMenu === menuList[2].name && <Favourite />}
            {selectedMenu === menuList[3].name && <Message />}
            {selectedMenu === menuList[4].name && <Notifications />}
            {selectedMenu === menuList[5].name && <MyLoanPost />}
            {selectedMenu === menuList[6].name && <Settings />}
          </RightColumn>
        </ColumnWrapper>
      </Container>
    </>
  );
};
