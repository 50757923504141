import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 36px;
  line-height: 51px;
  color: ${({ color }) => (color ? color : "#fff")};
  font-weight: 600;
  letter-spacing: -0.54px;
`;

export const H2 = styled.h2`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "28px")};
  color: ${({ color }) => (color ? color : "#494949")};
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0.27px;
`;

export const H3 = styled.h3`
  font-size: 18px;
  color: ${({ color }) => (color ? color : "#494949")};
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.27px;
`;

export const Body1 = styled.span`
  color: ${({ color }) => (color ? color : "#494949")};
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.24px;
`;

export const Body1Bold = styled.span`
  color: ${({ color }) => (color ? color : "#494949")};
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
`;

export const Body2Bold = styled.span`
  color: ${({ color }) => (color ? color : "#494949")};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
`;

export const Body2 = styled.span`
  color: ${(props) => (props.color ? props.color : "#9A9999")};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.21px;
`;

export const Body3 = styled.span`
  color: ${(props) => (props.color ? props.color : "#494949")};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.21px;
`;

export const CommonContainer = styled.div`
  color: ${({ color }) => (color ? color : "#494949")};
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.24px;

  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
    letter-spacing: 0.27px;
    padding-top: 10px;
    padding-bottom: 2px;
  }
  h1 {
    font-size: 36px;
    padding-bottom: 18px;
  }
  h2 {
    font-size: 28px;
    padding-bottom: 14px;
  }
  h3 {
    font-size: 18px;
  }
  p,
  div {
    padding-bottom: 10px;
  }

  ::after {
    content: "";
    display: block;
    margin-bottom: 35px;
  }
  img {
    padding: 0 10px;
  }
`;
