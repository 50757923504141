import Api from "../api/api";
import { useQuery } from "react-query";

const getJobBoard = async (_, page) => {
  const { data } = await Api.get(`jobs?page=${page}`);
  return data.data;
};

export default (props) => {
  return useQuery(["jobBoard", props], getJobBoard);
};
