import React from "react";
import {
  Container,
  // Container2,
  SlickSlider,
} from "../../components";
import Hero from "./hero/hero";
import SocialSearch from "./socialSearch/socialSearch";
// import HomeColumnList from "./homeColumnList/homeColumnList";
import Subscribe from "./subscribe/subscribe";
import useCategories from "../../hooks/useCategories";
import CommunityBoard from "./communityBoard/communityBoard";
import PropertyPicks from "../home/propertyPicks/propertyPicks";

export default () => {
  const { isLoading, data } = useCategories();

  return (
    <>
      {!isLoading && <Hero categories={data} />}
      <Container>
        {!isLoading && <SlickSlider type1 data={data[0]} marginTop={30} />}
      </Container>
      <Container>
        {!isLoading && <SlickSlider type1 data={data[1]} />}
      </Container>
      {/* <Container2>
        {!isLoading && <SlickSlider type2 data={data[1]} />}
      </Container2> */}
      <Container>
        {!isLoading && <SlickSlider type1 data={data[2]} />}
        {/* <HomeColumnList /> */}
        <CommunityBoard />
        <PropertyPicks />
        <SocialSearch />
        <Subscribe />
      </Container>
    </>
  );
};
