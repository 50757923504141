import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
} from "../../../components";
import useEventDetail from "../../../hooks/useEventDetail";
import {
  BlogImage,
  BlogInfo,
  BlogInfoElement,
  InfoIcon,
  BlogContent,
  BlogTitle,
  BlogContainer,
  BlogContentContainer,
} from "../../blogs/blogDetail/blogDetailElements";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdDateRange } from "react-icons/md";
import ReactHtmlParser from "react-html-parser";

export default () => {
  const { eventId } = useParams();
  const { isLoading, data } = useEventDetail(eventId);

  console.log(22, data);

  return (
    <>
      <HeroSection title={data?.title} description={data?.sub_title} />
      <Container>
        <BreadCrumbs
          data={[
            { title: "Event", pathname: "/events" },
            { title: `${data?.title}` },
          ]}
        />
        {isLoading ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <BlogContainer>
            <BlogImage img={data?.image} />
            <BlogContentContainer>
              <BlogInfo>
                {/* <InfoIcon>
                  <FaUserCheck />
                </InfoIcon>
                <BlogInfoElement>{data?.author}</BlogInfoElement> */}
                <InfoIcon>
                  <MdDateRange />
                </InfoIcon>
                <BlogInfoElement>
                  {moment(data?.start_date).format("MMM DD, YYYY")}
                  {" - "}
                  {moment(data?.end_date).format("MMM DD, YYYY")}
                </BlogInfoElement>
              </BlogInfo>
              <div style={{ paddingBottom: 10 }}>
                <div>
                  <Typography body1Bold>Venue: </Typography>
                  <Typography body2>{data?.venue}</Typography>
                </div>
                <div>
                  <Typography body1Bold>Link: </Typography>
                  <a href={data?.link} target="_blank">
                    {data?.link}
                  </a>
                </div>
                <div>
                  <Typography body1Bold>Organizer: </Typography>
                  <Typography body2>{data?.organizer}</Typography>
                </div>
              </div>
              <BlogTitle>{data?.title}</BlogTitle>
              <BlogContent>{ReactHtmlParser(data?.description)}</BlogContent>
            </BlogContentContainer>
          </BlogContainer>
        )}
      </Container>
    </>
  );
};
