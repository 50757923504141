export const setLocalStorageToken = (token) =>
  localStorage.setItem("@bighound_token", JSON.stringify(token));

export const setLocalStorageUser = (user) =>
  localStorage.setItem("@bighound_user", JSON.stringify(user));

export const getLocalStorageToken = () =>
  JSON.parse(localStorage.getItem("@bighound_token"));

export const getLocalStorageUser = () =>
  JSON.parse(localStorage.getItem("@bighound_user"));

// export const setSessionStorageToken = (token) =>
//   sessionStorage.setItem("@bighound_token", token);

// export const setSessionStorageUser = (user) =>
//   sessionStorage.setItem("@bighound_user", JSON.stringify(user));

// export const getSessionStorageToken = () =>
//   JSON.parse(sessionStorage.getItem("@bighound_token"));

// export const getRememberMe = () =>
//   JSON.parse(localStorage.getItem("@bighound_remember_me"));

// export const setRememberMe = (rememberMe) =>
//   localStorage.setItem("@bighound_remember_me", rememberMe);
