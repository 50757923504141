import React from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import Container from "../Container/Container";
import { ContentWrap } from "./LoanCommentsElements";
import InfoWrap from "./InfoWrap";
import CommentBox from "./CommentBox";
import CommentList from "./CommentList";
import useLoanDetail from "../../hooks/useLoanDetail";

export default () => {
  const { id } = useParams();
  const { isLoading, data: loanDetail, refetch } = useLoanDetail(id);

  return (
    <Container>
      <BreadCrumbs
        data={[
          { title: "Community Board", pathname: "/communityBoard" },
          { title: loanDetail ? loanDetail.title : "" },
        ]}
      />
      <ContentWrap>
        <InfoWrap
          data={loanDetail}
          isLoading={isLoading}
          refetchLoanDetail={refetch}
        />
        <CommentBox id={id} refetchLoanDetail={refetch} />
        {loanDetail?.comments && loanDetail.comments.length > 0 && (
          <CommentList
            commentList={loanDetail.comments}
            loanId={id}
            refetchLoanDetail={refetch}
          />
        )}
      </ContentWrap>
    </Container>
  );
};
