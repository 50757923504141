import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
} from "../../../components";
import useJobBoardDetail from "../../../hooks/useJobBoardDetail";
import {
  BlogImage,
  BlogInfo,
  BlogInfoElement,
  InfoIcon,
  BlogContent,
  BlogTitle,
  BlogContainer,
  BlogContentContainer,
} from "../../blogs/blogDetail/blogDetailElements";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdDateRange } from "react-icons/md";
import ReactHtmlParser from "react-html-parser";

export default () => {
  const { jobBoardId } = useParams();
  const { isLoading, data } = useJobBoardDetail(jobBoardId);

  return (
    <>
      <HeroSection title={data?.title} description={data?.sub_title} />
      <Container>
        <BreadCrumbs
          data={[
            { title: "Job Board", pathname: "/jobBoard" },
            { title: `${data?.title}` },
          ]}
        />
        {isLoading ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <BlogContainer>
            <BlogImage img={data?.image} />
            <BlogContentContainer>
              <BlogInfo>
                <InfoIcon>
                  <MdDateRange />
                </InfoIcon>
                <BlogInfoElement>
                  {moment(data?.date).format("MMMM DD, YYYY")}
                </BlogInfoElement>
              </BlogInfo>
              <BlogTitle>{data?.title}</BlogTitle>
              <div style={{ paddingBottom: 5 }}>
                <Typography body1>Category: &nbsp;</Typography>
                <Typography body2>{data?.category}</Typography>
              </div>
              <BlogContent>{ReactHtmlParser(data?.content)}</BlogContent>
            </BlogContentContainer>
          </BlogContainer>
        )}
      </Container>
    </>
  );
};
