import Api from "../api/api";
import { useQuery } from "react-query";

const getJobDetail = async (_, jobId) => {
  const { data } = await Api.get(`job/${jobId}`);
  return data.data.job;
};

export default (props) => {
  return useQuery(["job-detail", props], getJobDetail);
};
