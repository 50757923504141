import Api from "../api/api";
import { useQuery } from "react-query";

const getNotifications = async () => {
  const { data } = await Api.get("notifications");
  return data.data;
};

export default (props) => {
  return useQuery(["notifications", props], getNotifications);
};
