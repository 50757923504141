export default [
  {
    name: "fullName",
    label: "Full Name",
    placeholder: "Enter Your Full Name",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "emailAddress",
    label: "Email Address",
    placeholder: "Enter Your Email Address",
    type: "email",
    value: "",
    required: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    placeholder: "(123) 456-7890",
    type: "text",
    value: "",
    required: false,
  },
  {
    name: "message",
    label: "Message",
    placeholder: "",
    type: "textArea",
    value: "",
    required: true,
  },
];
