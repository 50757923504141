import styled from "styled-components";
import colors from "../../../constants/colors";
import { sm } from "../../../constants/layout";

export const SubscribeContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 50px 0;
  text-align: center;
`;

export const SubscribeTitle = styled.div`
  padding: 12px 0;
`;

export const SubscribeForm = styled.form`
  display: flex;
  margin: 20px 0;
  /* text-align: center; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${sm}) {
    flex-direction: column;
  }
`;

export const SubscribeInput = styled.input`
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border: none;
  outline: none;
  background-color: #f2f2f2;
  border-radius: 4px;
  font-weight: 300;
  margin-bottom: 10px;

  ::placeholder {
    color: #b0b0b0;
  }
`;

export const SubscribeInputWrap = styled.div`
  width: 60%;
  @media screen and (max-width: ${sm}) {
    width: 100%;
  }
`;

export const SubscribeButton = styled.button`
  display: flex;
  width: 170px;
  height: 60px;
  background-color: ${colors.primary};
  border: none;
  color: white;
  font-size: 0.8rem;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
  outline: none;

  &:hover {
    filter: brightness(85%);
  }
`;

export const SubscribeIcon = styled.span`
  display: flex;
  font-size: 20px;
  margin-right: 10px;
`;
