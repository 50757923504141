import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("/profile/")) {
      window.scrollTo(0, 300);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};
