import React from "react";
import Slider from "react-slick";
import Typography from "../Typography/Typography";
import Button from "../Button/Button";
import Image from "../Image/Image";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import colors from "../../constants/colors";
import {
  SlickSliderContainer,
  SlickTitle,
  SliderWrapper,
  SliderCardWrapper,
  SliderCard,
  SliderCardContent,
  PrevButton,
  NextButton,
} from "./SlickSliderElements";
import ViewAll from "../ViewAll/ViewAll";
import { useHistory } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const defaultSettings = {
  infinite: true,
  speed: 500,
  arrows: false,
};

export default ({ type1, type2, data, marginTop }) => {
  const [settings, setSettings] = React.useState(defaultSettings);
  const sliderRef = React.useRef(null);
  const history = useHistory();

  const updateSettings = () => {
    const windowWidth = window.innerWidth;
    let tempSettings;

    if (windowWidth < 490) {
      tempSettings = {
        slidesToScroll: 1,
        slidesToShow: 1,
      };
    } else if (windowWidth < 670) {
      tempSettings = {
        slidesToScroll: 2,
        slidesToShow: 2,
      };
    } else if (windowWidth < 900) {
      tempSettings = {
        slidesToScroll: 3,
        slidesToShow: 3,
      };
    } else {
      tempSettings = {
        slidesToScroll: 4,
        slidesToShow: 4,
      };
    }
    setSettings({
      ...defaultSettings,
      ...tempSettings,
    });
  };

  React.useEffect(() => {
    updateSettings();
    window.addEventListener("resize", updateSettings);
  }, []);

  const { title: categoryTitle, subcategories } = data;

  let sliderItems = subcategories;
  const subcategoriesLength = subcategories.length;

  // slider needs atleast 4 items.
  if (subcategoriesLength < 4) {
    Array(4 - subcategoriesLength)
      .fill("")
      .forEach(() => {
        if (sliderItems.length < 4) {
          sliderItems = [...sliderItems, ...subcategories];
        }
      });
  }

  const handleViewAll = () => {
    history.push({
      pathname: "/allSubCategoryList",
      search: `?type=${categoryTitle}`,
    });
  };

  let sliderTitle;

  if (categoryTitle.includes("Business")) {
    sliderTitle = "Business Professional Solutions";
  } else {
    sliderTitle = `${categoryTitle} Real Estate Solutions`;
  }

  return (
    <SlickSliderContainer marginTop={marginTop}>
      <SlickTitle>
        <Typography h3 color={(type1 && colors.primary) || (type2 && "#fff")}>
          START YOUR SEARCH
        </Typography>
        <Typography h2 color={type2 && "#fff"}>
          {sliderTitle}
        </Typography>
      </SlickTitle>
      {subcategoriesLength > 4 && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ViewAll white={type2} onClick={handleViewAll} />
        </div>
      )}
      <SliderWrapper>
        <Slider {...settings} ref={sliderRef}>
          {sliderItems.map(({ id: subCategoryId, title, image }) => (
            <div key={subCategoryId}>
              <SliderCardWrapper>
                <SliderCard>
                  <Image
                    src={image}
                    style={{
                      width: "100%",
                      height: 200,
                      backgroundColor: "#fafafa",
                    }}
                  />
                  <SliderCardContent>
                    <Typography h3 minHeight={52}>
                      {title}
                    </Typography>
                    <Button
                      marginTop={10}
                      onClick={() =>
                        history.push({
                          pathname: "/subCategoryList",
                          search: `?type=${categoryTitle}&name=${title}`,
                        })
                      }
                    >
                      Search
                    </Button>
                  </SliderCardContent>
                </SliderCard>
              </SliderCardWrapper>
            </div>
          ))}
        </Slider>
        {subcategoriesLength > 4 && (
          <>
            <PrevButton onClick={() => sliderRef.current.slickPrev()}>
              <IoMdArrowBack />
            </PrevButton>
            <NextButton onClick={() => sliderRef.current.slickNext()}>
              <IoMdArrowForward />
            </NextButton>
          </>
        )}
      </SliderWrapper>
    </SlickSliderContainer>
  );
};
