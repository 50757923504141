export default [
  {
    name: "firstName",
    label: "First Name",
    placeholder: "Enter your first name",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "lastName",
    label: "Last Name",
    placeholder: "Enter your last name",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "address",
    label: "Address",
    placeholder: "Enter your address",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "state",
    label: "State",
    placeholder: "Select your state",
    type: "select",
    value: "",
    required: true,
  },
  {
    name: "city",
    label: "City",
    placeholder: "Enter your city",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "zipCode",
    label: "Zip Code",
    placeholder: "Enter your zip code",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "jobTitle",
    label: "Job Title",
    placeholder: "Enter job title",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "company",
    label: "Company",
    placeholder: "Enter company",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    placeholder: "(123) 456-7890",
    type: "tel",
    value: "",
    required: true,
  },
  {
    name: "emailAddress",
    label: "Email Address",
    placeholder: "Enter your email address",
    type: "email",
    value: "",
    required: true,
  },
  {
    name: "password",
    label: "Password",
    placeholder: "Enter password",
    type: "password",
    value: "",
    required: true,
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    placeholder: "Confirm password",
    type: "password",
    value: "",
    required: true,
  },
];

// export default [
//   {
//     name: "firstName",
//     label: "First Name",
//     placeholder: "Enter your first name",
//     type: "text",
//     value: "asdfasdf",
//     required: true,
//   },
//   {
//     name: "lastName",
//     label: "Last Name",
//     placeholder: "Enter your last name",
//     type: "text",
//     value: "asdfasdf",
//     required: true,
//   },
//   {
//     name: "jobTitle",
//     label: "Job Title",
//     placeholder: "Enter job title",
//     type: "text",
//     value: "asdf",
//     required: true,
//   },
//   {
//     name: "company",
//     label: "Company",
//     placeholder: "Enter company",
//     type: "text",
//     value: "asdf",
//     required: true,
//   },
//   {
//     name: "phoneNumber",
//     label: "Phone Number",
//     placeholder: "Enter your phone number",
//     type: "text",
//     value: "0123456789",
//     required: true,
//   },
//   {
//     name: "emailAddress",
//     label: "Email Address",
//     placeholder: "Enter your email address",
//     type: "email",
//     value: "bimalgrg519@gmail.com",
//     required: true,
//   },
//   {
//     name: "password",
//     label: "Password",
//     placeholder: "Enter password",
//     type: "password",
//     value: "S0lution",
//     required: true,
//   },
//   {
//     name: "confirmPassword",
//     label: "Confirm Password",
//     placeholder: "Confirm password",
//     type: "password",
//     value: "S0lution",
//     required: true,
//   },
// ];
