import Api from "../api/api";
import { useQuery } from "react-query";

const getBusinessProfile = async () => {
  const { data } = await Api.get("profile/business-profile");
  return data.data;
};

export default () => {
  return useQuery("businessProfile", getBusinessProfile);
};
