import React, { useEffect, useState } from "react";
import useProfileDetail from "./hooks/useProfileDetail";
import {
  getLocalStorageUser,
  setLocalStorageUser,
} from "./utilities/setStorage";

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const { data } = useProfileDetail(currentUser ? currentUser.id : null);

  useEffect(() => {
    const currentUser = getLocalStorageUser();
    if (currentUser) {
      setCurrentUser(currentUser);
    }
    setPending(false);
  }, []);

  useEffect(() => {
    if (data) {
      setCurrentUser(data.profile);
      setLocalStorageUser(data.profile);
    }
  }, [data]);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <AppContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
