import React from "react";
import { Typography } from "../index";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from "react-icons/md";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h2>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default ({ open, close, data, title }) => {
  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title" onClose={close}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ width: 800 }}>
          {data.map(({ label, value, newLine }, index) => (
            <div
              key={label}
              style={{
                padding: "15px 0",
                borderBottom:
                  data.length === index + 1 ? "none" : "0.5px solid #f1f1f1",
              }}
            >
              <Typography body1Bold>{label}:&nbsp;&nbsp;&nbsp;</Typography>
              {newLine ? (
                <div>
                  <Typography body1>{value ? value : "N/A"}</Typography>
                </div>
              ) : (
                <Typography body1>{value ? value : "N/A"}</Typography>
              )}
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
