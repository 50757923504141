import styled from "styled-components";
import colors from "../../../constants/colors";
import { md, sm } from "../../../constants/layout";

export const BreadCrumbsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media screen and (max-width: ${md}) {
    flex-direction: column;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  width: 33%;
  flex-direction: column;
  background-color: #fff;
  padding: 28px 25px;

  @media screen and (max-width: ${md}) {
    width: 100%;
  }
`;

export const ProfileWrap = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

export const ProfileAvatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 40px;
  object-fit: cover;
`;

export const ProfileTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
  justify-content: center;
`;

export const FavIcon = styled.span`
  color: ${colors.primary};
  font-size: 22px;
  cursor: pointer;
`;

export const InfoRowWrap = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;

  .profile-detail {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    text-decoration: none;
  }
  .profile-detail.sms {
    display: none;
  }
  @media screen and (max-width: ${sm}) {
    .profile-detail.sms {
      display: flex;
    }
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const InfoIcon = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: ${({ color }) => color};
  color: #fff;
  margin-right: 10px;
`;

export const VisitWebsiteBtn = styled.button`
  display: flex;
  border: none;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 0 20px;
  background-color: ${colors.primary};
  border-radius: 20px;

  &:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(85%);
  }
`;

export const WebsiteIcon = styled.span`
  font-size: 22px;
  color: #fff;
  margin-right: 10px;
  display: flex;
`;

export const RightColumn = styled.div`
  display: flex;
  width: 66%;
  flex-direction: column;
  background-color: #fff;
  padding: 28px 25px;

  @media screen and (max-width: ${md}) {
    width: 100%;
  }
`;

export const DetailWrap = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 20px;

  ul {
    padding-left: 10px;
  }
`;
