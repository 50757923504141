import styled from "styled-components";
import { lg, md, sm } from "../../constants/layout";

export const ListWrap = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-bottom: 40px;
`;

export const ListItem = styled.div`
	width: 24%;
	margin-bottom: 15px;
	background-color: white;

	@media screen and (max-width: ${lg}) {
		width: 33%;
	}
	@media screen and (max-width: ${md}) {
		width: 49%;
	}
	@media screen and (max-width: ${sm}) {
		width: 100%;
	}
`;

export const ListItemAvatar = styled.img`
	width: 100%;
	height: 210px;
	border-radius: 4px;
	object-fit: cover;
`;

export const ListCardContent = styled.div`
	padding: 15px 15px 25px;
	background-color: #fff;
`;

export const InvisibleItem = styled.div`
	width: 24%;
	margin-bottom: 15px;
	@media screen and (max-width: ${lg}) {
		width: 33%;
	}
`;
