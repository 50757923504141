import React from "react";
import {
  BreadCrumbs,
  Container,
  HeroSection,
  Typography,
  Button,
  FormLabel,
  FormInput,
} from "../../components";
import {
  ColumnWrapper,
  LeftColumn,
  InfoWrap,
  InfoItem,
  InfoIcon,
  RightColumn,
  InputListWrap,
  ErrorText,
  MessageTextArea,
} from "./contactUsElements";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import useForm from "../../hooks/useForm";
import validate from "../../utilities/validate";
import info from "./info";
import usePageContent from "../../hooks/usePageContent";
import useSiteSettings from "../../hooks/useSiteSettings";
import ReactHtmlParser from "react-html-parser";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosCall, IoMdMail } from "react-icons/io";
import useContactUs from "../../hooks/useContactUs";
import { useMutation } from "react-query";
import Alert from "@material-ui/lab/Alert";

export default () => {
  const { values, handleChange, setValues } = useForm(info);
  const [formErrors, setFormErrors] = React.useState({});
  const [infoList, setInfoList] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState("");

  const { isLoading: isLoadingSiteSettings, data: siteSettingsData } =
    useSiteSettings();

  const { isLoading: isLoadingPageContent, data: pageContentData } =
    usePageContent("contact");
  const [mutateContactuUs, { isLoading: isLoadingContactUs }] = useMutation(
    useContactUs,
    {
      onSuccess: (response) => {
        setSuccessMessage(response.message);
        setValues(info);

        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      },
      onError: () => {
        alert("Something went wrong");
      },
    }
  );

  React.useEffect(() => {
    if (siteSettingsData) {
      const {
        address_primary,
        address_secondary,
        contact_primary,
        // contact_secondary,
        email,
      } = siteSettingsData[0];

      setInfoList([
        {
          icon: <FaMapMarkerAlt />,
          info: address_primary,
          info2: address_secondary,
          // info: [address_primary, address_secondary].join(", "),
        },
        {
          icon: <IoIosCall />,
          info: contact_primary,
          // info: [contact_primary, contact_secondary].join(", "),
        },
        {
          icon: <IoMdMail />,
          info: email,
        },
      ]);
    }
  }, [siteSettingsData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});
    setSuccessMessage("");

    const tempErrors = validate(values);
    if (Object.keys(tempErrors).length) {
      setFormErrors(tempErrors);
    } else {
      const fullName = values.find((d) => d.name === "fullName").value;
      const emailAddress = values.find((d) => d.name === "emailAddress").value;
      const phoneNumber = values.find((d) => d.name === "phoneNumber").value;
      const message = values.find((d) => d.name === "message").value;

      mutateContactuUs({
        name: fullName,
        email: emailAddress,
        phone: phoneNumber,
        message,
      });
    }
  };

  return (
    <>
      <HeroSection
        title={pageContentData?.title ? pageContentData.title : "Contact us"}
        description={
          pageContentData?.sub_title ? pageContentData.sub_title : ""
        }
      />
      <Container>
        <BreadCrumbs
          data={[
            {
              title: pageContentData?.title
                ? pageContentData.title
                : "Contact us",
            },
          ]}
        />
        <ColumnWrapper>
          <LeftColumn>
            {isLoadingPageContent || isLoadingSiteSettings ? (
              <Typography h3>Loading...</Typography>
            ) : (
              <>
                <Typography commonContainer>
                  {ReactHtmlParser(
                    pageContentData?.content
                      ? pageContentData.content
                      : "<h2>Contact Information</h2>"
                  )}
                  <InfoWrap>
                    {infoList.map(({ icon, info, info2 }, index) => (
                      <InfoItem key={index}>
                        <InfoIcon>{icon}</InfoIcon>
                        <div>
                          <Typography body1>{info}</Typography>
                          {info2 && (
                            <>
                              <br />
                              <Typography body1>{info2}</Typography>
                            </>
                          )}
                        </div>
                      </InfoItem>
                    ))}
                  </InfoWrap>
                </Typography>
                {siteSettingsData[0].longitude &&
                  siteSettingsData[0].longitude && (
                    <MapContainer
                      center={[
                        siteSettingsData[0].longitude,
                        siteSettingsData[0].latitude,
                      ]}
                      zoom={13}
                      scrollWheelZoom={false}
                      style={{ height: 300 }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        position={[
                          siteSettingsData[0].longitude,
                          siteSettingsData[0].latitude,
                        ]}
                      >
                        <Popup>
                          A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                      </Marker>
                    </MapContainer>
                  )}
              </>
            )}
          </LeftColumn>
          <RightColumn>
            <form onSubmit={handleSubmit}>
              <Typography h2>Contact Form</Typography>
              {successMessage && (
                <Alert severity="success">{successMessage}</Alert>
              )}
              <InputListWrap>
                {values.map(
                  ({ name, label, placeholder, type, value, required }) =>
                    type === "textArea" ? (
                      <div key={name} style={{ paddingBottom: 5 }}>
                        <FormLabel htmlFor={name} required={required}>
                          {label}
                        </FormLabel>
                        <MessageTextArea
                          id={name}
                          name={name}
                          rows={6}
                          value={value}
                          onChange={handleChange}
                        />
                        {<ErrorText>{formErrors[name]}</ErrorText>}
                      </div>
                    ) : (
                      <div key={name} style={{ paddingBottom: 5 }}>
                        <FormLabel htmlFor={name} required={required}>
                          {label}
                        </FormLabel>
                        <FormInput
                          id={name}
                          name={name}
                          type={type}
                          placeholder={placeholder}
                          value={value}
                          onChange={handleChange}
                        />
                        {<ErrorText>{formErrors[name]}</ErrorText>}
                      </div>
                    )
                )}
              </InputListWrap>
              <Button type="submit" isLoading={isLoadingContactUs}>
                Send
              </Button>
            </form>
          </RightColumn>
        </ColumnWrapper>
      </Container>
    </>
  );
};
