import styled from "styled-components";
import heroImage2 from "../../assets/heroImage2.png";
import { MAX_WIDTH } from "../../constants/layout";

export const HeroSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background-image: url(${heroImage2});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	padding: 0px;
`;
export const WrapHero = styled.div`
	max-width: ${MAX_WIDTH};
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: stretch;
`;

export const HeroSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px 0;

	@media screen and (max-width: 1026px) {
		padding: 30px 10px;
	}
`;

export const DescriptionWrap = styled.div`
	max-width: 750px;
`;
export const DescriptionWrap2 = styled.div`
	max-width: 750px;
	margin: 7px 0;
`;

export const HorizontalBar = styled.div`
	background: #fff;
	width: 5px;
	height: 100%;
	top: 0px;
	transform: skewX(-13deg);
	margin-right: 3.5rem;

	@media screen and (max-width: 1026px) {
		width: 100% !important;
		height: 5px !important;
		transform: none;
		margin-right: 0;
	}
`;

export const Span1 = styled.span`
	color: #fb7d2d;
	border: 1px solid #fff;
	background: #fff;
	padding: 5px 10px;
	border-radius: 2.5px;
	font-weight: 800;
	display: inline-block;
	font-size: 1.45rem;
	line-height: 1;
`;

export const Em = styled.span`
	color: #000;
	font-weight: 600;
	font-style: italic;
	font-size: 0.85rem;
`;
