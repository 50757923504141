import React from "react";
import { Typography } from "../../components";
import { useMutation } from "react-query";
import { DismissButton } from "./profileElements";
import moment from "moment";
import useNotifications from "../../hooks/useNotifications";
import useNotificationDismiss from "../../hooks/useNotificationDismiss";
import useNotificationClearAll from "../../hooks/useNotificationClearAll";
import { useHistory } from "react-router-dom";

export default () => {
  const { isLoading, data, refetch } = useNotifications();
  const history = useHistory();

  const [mutateNotificationDismiss] = useMutation(useNotificationDismiss, {
    onSuccess: () => refetch(),
  });
  const [mutateNotificationClearAll] = useMutation(useNotificationClearAll, {
    onSuccess: () => refetch(),
  });

  if (isLoading) {
    return <Typography body1>Loading...</Typography>;
  }

  const handleDismiss = (id) => mutateNotificationDismiss(id);

  const notificationType = (type) => {
    if (type === "message") {
      return "Message";
    } else if (type === "comment") {
      return "Comment";
    } else if (type === "favourite") {
      return "Favorite Received";
    }
  };

  const handleClearAll = () => mutateNotificationClearAll();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography h3>Notifications</Typography>
        {data.notifications.length ? (
          <div
            style={{
              border: "1px solid #aaa",
              padding: "4px 10px",
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={handleClearAll}
          >
            <span style={{ fontSize: 14, color: "#494949" }}>Clear All</span>
          </div>
        ) : null}
      </div>
      <div>
        {data.notifications.length ? (
          data.notifications.map(
            ({
              id,
              first_name,
              last_name,
              type,
              email,
              content,
              created_by,
              loan_post_id,
            }) => (
              <div
                key={id}
                style={{
                  padding: "20px 0",
                  borderBottom: "1px solid #efefef",
                  marginBottom: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      color: "#494949",
                      fontSize: 42,
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <span>{moment(created_by).format("DD")}</span>
                  </div>
                  <div
                    style={{
                      color: "#494949",
                      fontSize: 16,
                      display: "flex",
                      alignItems: "flex-end",
                      paddingBottom: 12,
                      paddingLeft: 5,
                      lineHeight: "1.2rem",
                    }}
                  >
                    <span style={{ fontWeight: 500 }}>
                      {moment(created_by).format("ddd").toUpperCase()} <br />
                      {moment(created_by).format("MMM, YYYY").toUpperCase()}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    height: 1,
                    backgroundColor: "#efefef",
                    marginTop: 12,
                    marginBottom: 18,
                  }}
                />
                <div style={{ display: "flex" }}>
                  <Typography body1Bold>{notificationType(type)}</Typography>
                </div>
                <Typography body1>{content ? `"${content}"` : null}</Typography>
                <div>
                  <Typography body2>
                    {first_name} {last_name} ({email})
                  </Typography>
                </div>
                <div>
                  {type === "comment" && (
                    <DismissButton
                      onClick={() =>
                        history.push(`/communityBoard/${loan_post_id}`)
                      }
                    >
                      <span>View</span>
                    </DismissButton>
                  )}
                  <DismissButton onClick={() => handleDismiss(id)}>
                    <span>Dismiss</span>
                  </DismissButton>
                </div>
              </div>
            )
          )
        ) : (
          <div style={{ marginTop: 20 }}>
            <Typography body1>You do not have any notifications</Typography>
          </div>
        )}
      </div>
    </>
  );
};
