import React from "react";
import { useHistory } from "react-router-dom";
import {
	HeroSection,
	BreadCrumbs,
	Container,
	Typography,
	Button,
} from "../../components";
import useCategories from "../../hooks/useCategories";
import {
	ListWrap,
	ListItem,
	ListItemAvatar,
	ListCardContent,
	InvisibleItem,
} from "./allSubCategoryListElements";
import queryString from "query-string";
import CommunityBoard from "../home/communityBoard/communityBoard";
import PropertyPicks from "../home/propertyPicks/propertyPicks";
import SocialSearch from "../home/socialSearch/socialSearch";
import Subscribe from "../home/subscribe/subscribe";

export default () => {
	const { type } = queryString.parse(location.search, { parseNumbers: true });
	const { data } = useCategories();
	const history = useHistory();

	let subCategories = [];

	if (data) {
		subCategories = data.find(({ title }) => title === type).subcategories;
	}

	const numberOfItems = 4;
	const remainder = subCategories.length % numberOfItems;
	let invisibleItems = [];

	if (remainder) {
		invisibleItems = Array(numberOfItems - remainder)
			.fill(new Date().toISOString())
			.map((d, i) => d + i);
	}

	return (
		<>
			<HeroSection
				title={
					type.includes("Business")
						? "Business Professional Solutions"
						: `${type} Real Estate Solutions`
				}
				description={
					type.includes("Business")
						? `Search For Business Professional Solutions`
						: `Search For ${type} Real Estate Professionals`
				}
				oftype={type}
			/>
			<Container>
				<BreadCrumbs
					data={[
						{
							title: type.includes("Business")
								? "Business Professional Solutions"
								: `${type} Real Estate Solutions`,
						},
					]}
				/>
				<ListWrap>
					{[...subCategories, ...invisibleItems].map((d) =>
						d.id ? (
							<ListItem key={d.id}>
								<ListItemAvatar src={d.image} />
								<ListCardContent>
									<Typography h3 minHeight={52}>
										{d.title}
									</Typography>
									<Button
										marginTop={10}
										onClick={() =>
											history.push({
												pathname: "/subCategoryList",
												search: `?type=${type}&name=${d.title}`,
											})
										}>
										<Typography body1 color="#fff">
											Search
										</Typography>
									</Button>
								</ListCardContent>
							</ListItem>
						) : (
							<InvisibleItem key={d} />
						)
					)}
				</ListWrap>

				<CommunityBoard />
				<PropertyPicks />
				<SocialSearch />
				<Subscribe />
			</Container>
		</>
	);
};
