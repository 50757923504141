import React from "react";
import {
  Button,
  Typography,
  FormLabel,
  FormInput,
  FormErrorText,
} from "../../components";
import { InputRow, InputWrap, DescriptionTextArea } from "./profileElements";
import useBasicProfile from "../../hooks/useBasicProfile";
import useBasicProfileUpdate from "../../hooks/useBasicProfileUpdate";
import useForm from "../../hooks/useForm";
import { useMutation } from "react-query";
import { AppContext } from "../../AppContext";
import Alert from "@material-ui/lab/Alert";
import { setLocalStorageUser } from "../../utilities/setStorage";
import validate from "../../utilities/validate";
import ReactSelect from "react-select";
import useStateList from "../../hooks/useStateList";
import { SHORT_DESCRIPTION_LENGTH } from "../../constants/constants";

const formData = [
  {
    name: "firstName",
    label: "First Name",
    placeholder: "Enter your first name",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "lastName",
    label: "Last Name",
    placeholder: "Enter your last name",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "address",
    label: "Address",
    placeholder: "Enter your address",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "state",
    label: "State",
    placeholder: "Select state",
    type: "select",
    value: "",
    required: true,
  },
  {
    name: "city",
    label: "City",
    placeholder: "Enter your city",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "zipCode",
    label: "Zip Code",
    placeholder: "Enter your zipcode",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "jobTitle",
    label: "Job Title",
    placeholder: "Enter job title",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "company",
    label: "Company",
    placeholder: "Enter company",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    placeholder: "(123) 456-7890",
    type: "tel",
    value: "",
    required: true,
  },
  {
    name: "website",
    label: "Website",
    placeholder: "Enter your website",
    type: "text",
    value: "",
    required: false,
  },
];

export default () => {
  const { setCurrentUser } = React.useContext(AppContext);
  const { values, setValues, handleChange } = useForm(formData);
  const { isLoading: isLoadingBasicProfile, data } = useBasicProfile();
  const [successMessage, setSuccessMessage] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [description, setDescription] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState(null);

  const { data: stateList } = useStateList();
  const [mutateBasicProfile, { isLoading: isLoadingBasicProfileUpdate }] =
    useMutation(useBasicProfileUpdate, {
      onSuccess: (response) => {
        const user = response.data.profile;
        setCurrentUser(user);
        setLocalStorageUser(user);
        setSuccessMessage(response.message);
      },
      onError: (error) => {
        if (error) {
          alert("Something went wrong during profile update.");
        }
      },
    });

  React.useEffect(() => {
    if (data && stateList) {
      const {
        first_name,
        last_name,
        address,
        company,
        phone_no,
        job_title,
        email,
        short_description,
        url,
        state,
        city,
        zip_code,
      } = data;

      const tempState = stateList.find((d) => d.name === state);

      // insert value to formData for state
      const tempProfile = formData.map((d) => {
        let value;
        if (d.name === "firstName") {
          value = first_name ? first_name : "";
        } else if (d.name === "lastName") {
          value = last_name ? last_name : "";
        } else if (d.name === "address") {
          value = address ? address : "";
        } else if (d.name === "jobTitle") {
          value = job_title ? job_title : "";
        } else if (d.name === "company") {
          value = company ? company : "";
        } else if (d.name === "phoneNumber") {
          setPhoneNumber(phone_no);
        } else if (d.name === "emailAddress") {
          value = email ? email : "";
        } else if (d.name === "website") {
          value = url ? url : "";
        } else if (d.name === "city") {
          value = city ? city : "";
        } else if (d.name === "zipCode") {
          value = zip_code ? zip_code : "";
        } else if (d.name === "state") {
          value = tempState
            ? {
                label: tempState.name,
                value: tempState.id,
              }
            : null;
        }
        return { ...d, value };
      });

      setDescription(
        short_description
          ? short_description.substring(0, SHORT_DESCRIPTION_LENGTH)
          : ""
      );
      setValues(tempProfile);
    }
  }, [data, stateList]);

  if (isLoadingBasicProfile) {
    return <Typography body1>Loading...</Typography>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});
    setSuccessMessage("");

    const tempErrors = validate(
      values.map((d) => ({
        ...d,
        value: d.name === "phoneNumber" ? phoneNumber : d.value,
      }))
    );

    if (Object.keys(tempErrors).length > 0) {
      setFormErrors(tempErrors);
    } else {
      const first_name = values.find((d) => d.name === "firstName").value;
      const last_name = values.find((d) => d.name === "lastName").value;
      const address = values.find((d) => d.name === "address").value;
      const company = values.find((d) => d.name === "company").value;
      const job_title = values.find((d) => d.name === "jobTitle").value;
      // const phone_no = values.find((d) => d.name === "phoneNumber").value;
      const website = values.find((d) => d.name === "website").value;
      const city = values.find((d) => d.name === "city").value;
      const zip_code = values.find((d) => d.name === "zipCode").value;
      const state_id = values.find((d) => d.name === "state").value.value;

      mutateBasicProfile({
        id: data.id,
        first_name,
        last_name,
        address,
        company,
        phone_no: phoneNumber,
        job_title,
        short_description: description,
        url: website,
        city,
        zip_code,
        state_id,
      });
    }
  };

  const handleChangeSelect = (item) => {
    setValues(
      values.map((d) => ({
        ...d,
        value: d.type === "select" ? item : d.value,
      }))
    );
  };

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescription(value ? value.substring(0, SHORT_DESCRIPTION_LENGTH) : "");
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography h3>Basic Profile</Typography>
      <Typography body1>Edit your profile below:</Typography>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <div style={{ marginTop: 30 }}>
        <Typography body1Bold>Basic Information</Typography>
        <InputRow>
          {values.map(({ name, label, placeholder, type, value, required }) => (
            <InputWrap key={name}>
              {type === "text" && (
                <>
                  <FormLabel htmlFor={name} required={required}>
                    {label}
                  </FormLabel>
                  <FormInput
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                  />
                </>
              )}
              {type === "tel" && (
                <>
                  <FormLabel htmlFor={name} required={required}>
                    {label}
                  </FormLabel>
                  <FormInput
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
                    value={phoneNumber}
                    // required
                    onChange={(e) => {
                      const tempValue = e.target.value.substring(0, 14);

                      var cleaned = ("" + tempValue).replace(/\D/g, "");
                      const match = cleaned.match(
                        /^(1|)?(\d{3})(\d{3})(\d{4})$/
                      );
                      if (match) {
                        const intlCode = match[1] ? "+1 " : "",
                          number = [
                            intlCode,
                            "(",
                            match[2],
                            ") ",
                            match[3],
                            "-",
                            match[4],
                          ].join("");

                        setPhoneNumber(number);
                        return;
                      }

                      setPhoneNumber(tempValue);
                    }}
                  />
                </>
              )}
              {type === "select" && (
                <div style={{ marginTop: 6 }}>
                  <FormLabel htmlFor={name} required={required}>
                    {label}
                  </FormLabel>
                  <ReactSelect
                    options={
                      stateList &&
                      stateList.map((d) => ({
                        label: d.name,
                        value: d.id,
                      }))
                    }
                    value={value}
                    onChange={(selectedItem) =>
                      handleChangeSelect(selectedItem)
                    }
                    isClearable
                  />
                </div>
              )}
              {<FormErrorText>{formErrors[name]}</FormErrorText>}
            </InputWrap>
          ))}
        </InputRow>
      </div>
      <div>
        <FormLabel htmlFor="description">Basic Description</FormLabel>
        <span style={{ marginLeft: 8, fontSize: 12, color: "#666" }}>
          (250 characters)
        </span>
        <DescriptionTextArea
          id="description"
          name="description"
          rows={4}
          value={description}
          onChange={handleChangeDescription}
        />
      </div>
      <Button
        type="submit"
        isLoading={isLoadingBasicProfileUpdate}
        marginTop={15}
      >
        Save Changes
      </Button>
    </form>
  );
};
