import React from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoLink,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtnLink,
  MenuBarsIcon,
  SignInSignOutBtn,
} from "./NavbarElements";
import Button from "../Button/Button";
import logo from "../../assets/logo.png";
import { FaSignInAlt, FaBars } from "react-icons/fa";
import Sidebar from "./Sidebar";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../AppContext";
import NavProfile from "./NavProfile";

export default () => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const history = useHistory();
  const { currentUser } = React.useContext(AppContext);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <Nav>
      <NavbarContainer>
        <NavLogoLink to="/">
          <NavLogo src={logo} />
        </NavLogoLink>
        <NavMenu>
          <NavItem>
            <NavLinks to="/">Home</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/aboutUs">About Us</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/blogs">Blogs</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/news">News & Articles</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/contactUs">Contact Us</NavLinks>
          </NavItem>
        </NavMenu>
        {currentUser ? (
          <NavProfile />
        ) : (
          <SignInSignOutBtn>
            <NavBtnLink to="/signIn">Sign In</NavBtnLink>
            <Button
              icon={<FaSignInAlt />}
              small
              onClick={() => history.push("/signUp")}
            >
              Sign Up
            </Button>
          </SignInSignOutBtn>
        )}
        <MenuBarsIcon onClick={toggleSidebar}>
          <FaBars />
        </MenuBarsIcon>
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </NavbarContainer>
    </Nav>
  );
};
