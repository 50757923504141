import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { AppProvider } from "./AppContext";
import AppRouter from "./AppRouter";
import VerifyEmail from "./screens/signIn/verifyEmail";
import ResetPassword from "./screens/signIn/resetPassword";
import CookieConsent from "react-cookie-consent";

const queryCache = new QueryCache();

function App() {
	return (
		<ReactQueryCacheProvider queryCache={queryCache}>
			<AppProvider>
				<Router>
					<Switch>
						<Route exact path="/verify-email/:id" component={VerifyEmail} />
						<Route
							exact
							path="/reset-password/:token/:email"
							component={ResetPassword}
						/>
						<Route path="/" component={AppRouter} />
					</Switch>
				</Router>
			</AppProvider>
			<CookieConsent>
				We use cookies to provide you a better experience and understand how our
				site is being used.
			</CookieConsent>
		</ReactQueryCacheProvider>
	);
}

export default App;
