import styled, { css } from "styled-components";

export const SlickSliderContainer = styled.div`
  padding: 15px 10px;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
`;

export const SlickTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SliderWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const SliderCardWrapper = styled.div`
  padding: 0 8px;
`;

export const SliderCard = styled.div`
  border-radius: 4px;
  overflow: hidden;
`;

export const SliderCardContent = styled.div`
  padding: 15px 15px 25px;
  background-color: #fff;
`;

const buttonStyle = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 34px;
  height: 34px;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  box-shadow: 0px 2px 15px #00000014;
  border-radius: 2px;
`;

export const PrevButton = styled.button`
  ${buttonStyle}
  left: -12px;
`;

export const NextButton = styled.button`
  ${buttonStyle}
  right: -12px;
`;
