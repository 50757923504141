import Api from "../api/api";
import { useQuery } from "react-query";

const getHotPropertyDetail = async (_, hotPropertyId) => {
  const { data } = await Api.get(`property/${hotPropertyId}`);
  return data.data.property;
};

export default (props) => {
  return useQuery(["hotProperty-detail", props], getHotPropertyDetail);
};
