import styled from "styled-components";
import { sm, md } from "../../../constants/layout";

export const ItemWrap = styled.div`
	width: 32.5%;
	background-color: #fff;
	padding: 16px;
	margin-bottom: 20px;
	border-radius: 4px;

	@media screen and (max-width: ${md}) {
		width: 49%;
	}
	@media screen and (max-width: ${sm}) {
		width: 100%;
	}
`;

export const LikeCommentWrap = styled.div`
	display: flex;
	align-items: center;
	border-radius: 2px;
	cursor: pointer;
	margin-top: 14px;

	:hover {
		font-weight: 500;
	}
`;

export const MoreBtn = styled.div`
	cursor: pointer;
	border-radius: 15px;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;

	:hover {
		background-color: #f1f1f1;
	}
`;
