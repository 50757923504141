import React from "react";
import { Typography } from "../../components";
import {
  ListItem,
  ListItemHeader,
  ListItemAvatar,
  ListHeaderTextWrap,
  ListItemInfoWrap,
  ListItemInfo,
  ListItemInfoIcon,
  ListItemInfoText,
  FavIcon,
  Tag,
  PremierIcon1,
  PremierIcon2,
} from "./listingElements";
import { IoIosCall } from "react-icons/io";
import { MdContactPhone } from "react-icons/md";
import { MdMessage } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import defaultProfilePicture from "../../assets/defaultProfilePicture.png";
import ContactDialog from "./contactDialog";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { useMutation } from "react-query";
import useMakeFavorite from "../../hooks/useMakeFavorite";
import { AppContext } from "../../AppContext";
import Tooltip from "@material-ui/core/Tooltip";
import { LoadMore } from "../../components";
import ClipLoader from "react-spinners/ClipLoader";

import { useHistory } from "react-router-dom";
import colors from "../../constants/colors";
import queryString from "query-string";
import { SHORT_DESCRIPTION_LENGTH } from "../../constants/constants";

const UsersNotAvailable = ({ selectedCategory, isSubCategoryList }) => {
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 100,
      }}
    >
      <Typography body1Bold>
        No such Person / Business is found on our platform.
      </Typography>
      <Typography body2>
        If you did not expect this response, refine the search criteria
      </Typography>
      {!isSubCategoryList && (
        <div style={{ paddingTop: 20 }}>
          <Typography body1Bold>Search our database for</Typography>
          <Tag
            selected
            onClick={() => {
              history.push({
                pathname: "/allSubCategoryList",
                search: `?type=${selectedCategory}`,
              });
            }}
          >
            <Typography body2 color="#fff">
              {selectedCategory}
            </Typography>
          </Tag>
        </div>
      )}
    </div>
  );
};

export default ({
  isLoading,
  isSuccess,
  dataPerPage,
  allData,
  refetch,
  handleLoadMore,
  isSubCategoryList,
  subCategoryName,
  selectedCategory,
}) => {
  const history = useHistory();
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { currentUser } = React.useContext(AppContext);
  const parsedQuery = queryString.parse(location.search, {
    parseNumbers: true,
  });

  const [mutateFavorite] = useMutation(useMakeFavorite, {
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      alert("Something went wrong during make favorite.");
    },
  });
  if (isLoading && !allData.length) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ClipLoader size={28} color={colors.primary} loading={true} />
      </div>
    );
  }

  if (isSuccess && !allData.length) {
    return (
      <UsersNotAvailable
        selectedCategory={selectedCategory}
        isSubCategoryList={isSubCategoryList}
      />
    );
  }

  const list = [
    {
      name: "Call Now",
      icon: <IoIosCall />,
      color: "#21CCB5",
    },
    {
      name: "Send SMS",
      icon: <MdMessage />,
      color: "#4297EB",
      className: "profile-sms",
    },
    {
      name: "Contact",
      icon: <MdContactPhone />,
      color: "#EBAC42",
      onClick: ({ id }) => {
        setIsDialogOpen(true);
        setSelectedUserId(id);
      },
    },
    {
      name: "View Details",
      icon: <FaEye />,
      color: "#FC7722",
      onClick: (profile) => {
        history.push({
          pathname: isSubCategoryList
            ? "/subCategoryList/profileDetail"
            : "/profileDetail",
          search: isSubCategoryList
            ? `?id=${profile.id}&type=${parsedQuery.type}&name=${subCategoryName}`
            : `?type=${parsedQuery.type}&id=${profile.id}`,
        });
      },
    },
  ];

  // if (!currentUser) {
  //   list = list.filter((d) => d.name !== "Contact");
  // }

  const handleFavorite = (favorited, id) => {
    mutateFavorite({
      user_id: id,
      favorited: !favorited,
    });
  };

  const href = (name, phone_no) => {
    if (name === "Call Now") {
      return `tel:${phone_no}`;
    } else if (name === "Send SMS") {
      return `sms:${phone_no}`;
    }
    return null;
  };

  return (
    <>
      <ContactDialog
        id={selectedUserId}
        open={isDialogOpen}
        close={() => setIsDialogOpen(false)}
      />
      {allData.map(
        (
          {
            id,
            first_name,
            last_name, // address, // phone_no, email,
            image,
            short_description,
            favorited,
            phone_no,
            company,
            ad,
          },
          profileIndex
        ) => (
          <ListItem key={id} selected={ad}>
            <ListItemHeader>
              <ListItemAvatar src={image ? image : defaultProfilePicture} />
              <div style={{ flex: 1, paddingLeft: 20 }}>
                <div style={{ display: "flex" }}>
                  <ListHeaderTextWrap>
                    <Typography h3>
                      {first_name} {last_name}
                    </Typography>
                    {/* <Typography body2>{address}</Typography> */}
                    <Typography body2>{company}</Typography>
                  </ListHeaderTextWrap>
                  {ad && <PremierIcon1>Premier</PremierIcon1>}
                  {!currentUser && (
                    <Tooltip title="Add to favorite" placement="top" arrow>
                      <FavIcon onClick={() => history.push("/signIn")}>
                        <FaRegHeart />
                      </FavIcon>
                    </Tooltip>
                  )}
                  {currentUser && currentUser.id !== id && (
                    <Tooltip
                      title={
                        favorited ? "Remove from favorite" : "Add to favorite"
                      }
                      placement="top"
                      arrow
                    >
                      <FavIcon onClick={() => handleFavorite(favorited, id)}>
                        {favorited ? <FaHeart /> : <FaRegHeart />}
                      </FavIcon>
                    </Tooltip>
                  )}
                </div>
                {ad && <PremierIcon2>Premier</PremierIcon2>}
                <ListItemInfoWrap>
                  {list.map(
                    ({ name, icon, color, onClick, className }, index) => (
                      <a
                        key={name}
                        className={className}
                        href={href(name, phone_no)}
                        style={{ textDecoration: "none", marginTop: 10 }}
                        onClick={() => {
                          if (name === "Contact" || name === "View Details") {
                            onClick(allData[profileIndex]);
                          }
                        }}
                      >
                        <ListItemInfo showBorder={list.length !== index + 1}>
                          <ListItemInfoIcon color={color}>
                            {icon}
                          </ListItemInfoIcon>
                          <ListItemInfoText>
                            <Typography body1>{name}</Typography>
                          </ListItemInfoText>
                        </ListItemInfo>
                      </a>
                    )
                  )}
                </ListItemInfoWrap>
              </div>
            </ListItemHeader>
            <Typography body1>
              {short_description
                ? short_description.substring(0, SHORT_DESCRIPTION_LENGTH)
                : null}
            </Typography>
          </ListItem>
        )
      )}
      <LoadMore
        dataPerPage={dataPerPage}
        allData={allData}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClick={handleLoadMore}
      />
    </>
  );
};
