import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 50px;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 0 20px;
  min-width: 140px;

  &:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(85%);
    /* opacity: 0.8; */
  }
`;

export const BtnIcon = styled.span`
  display: flex;
  font-size: 22px;
  margin-right: 5px;
  color: #fff;
`;
