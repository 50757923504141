import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
} from "../../components";
import usePageContent from "../../hooks/usePageContent";
import ReactHtmlParser from "react-html-parser";

export default () => {
  const { isLoading, data } = usePageContent("terms-and-conditions");
  return (
    <>
      <HeroSection
        title={data?.title ? data.title : "Terms & Conditions"}
        description={data?.sub_title ? data.sub_title : " "}
      />
      <Container>
        <BreadCrumbs
          data={[{ title: data?.title ? data.title : "Terms & Conditions" }]}
        />
        {isLoading ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <Typography commonContainer>
            {ReactHtmlParser(data.content)}
          </Typography>
        )}
      </Container>
    </>
  );
};
