import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
  Image,
  LoadMore,
} from "../../components";
import useEvents from "../../hooks/useJobBoard";
import {
  NewsWrap,
  NewsItem,
  NewsInfo,
  NewsInfoItem,
  NewsInfoIcon,
  NewsSubTitle,
  NewsContainer,
} from "../news/newsElements";
import { Link } from "react-router-dom";
import { MdDateRange } from "react-icons/md";
import moment from "moment";

export default () => {
  const [jobBoardList, setJobBoardList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  const { isLoading, data, isSuccess } = useEvents(currentPage);

  React.useEffect(() => {
    if (data) {
      if (currentPage === 1) {
        setJobBoardList(data.job);
      } else {
        setJobBoardList([...jobBoardList, ...data.job]);
      }
    }
  }, [data]);

  return (
    <>
      <HeroSection title="Job Board" description="" />
      <Container>
        <BreadCrumbs data={[{ title: "Job Board" }]} />
        {isLoading && jobBoardList.length === 0 ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <NewsWrap>
            {jobBoardList.map(
              ({ id, title, image, excerpt, category, date }) => (
                <NewsItem key={id}>
                  <Link to={`/jobBoard/${id}`}>
                    <Image
                      src={image}
                      style={{
                        width: "100%",
                        height: 250,
                        objectFit: "cover",
                      }}
                    />
                    <NewsContainer>
                      <Typography h3>{title}</Typography>
                      <NewsInfo>
                        <NewsInfoItem>
                          <NewsInfoIcon>
                            <MdDateRange />
                          </NewsInfoIcon>
                          <Typography body2>
                            {moment(date).format("MMMM DD, YYYY")}
                          </Typography>
                        </NewsInfoItem>
                      </NewsInfo>
                      <NewsInfo>
                        <NewsInfoItem>
                          <Typography body1>Category:&nbsp;</Typography>
                          <Typography body2>{category}</Typography>
                        </NewsInfoItem>
                      </NewsInfo>
                      <NewsSubTitle>
                        {excerpt && <Typography body1>{excerpt}</Typography>}
                      </NewsSubTitle>
                    </NewsContainer>
                  </Link>
                </NewsItem>
              )
            )}
          </NewsWrap>
        )}
        <LoadMore
          dataPerPage={data}
          allData={jobBoardList}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Container>
    </>
  );
};
