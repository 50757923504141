import React from "react";
import {
	BreadCrumbs,
	Container,
	HeroSection,
	Typography,

	// LoanInfo,
	LoadMore,
	ReportDialog,
	SendMessage,
} from "../../components";
import useLoanPostList from "../../hooks/useLoanPostList";
import { useMutation } from "react-query";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import colors from "../../constants/colors";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
	LikeCommentWrap,
	MoreBtn,
} from "../home/communityBoard/communityElements";
import useLoanPostLike from "../../hooks/useLoanPostLike";
import { FiMoreHorizontal, FiSend } from "react-icons/fi";
import { ItemWrap } from "../home/communityBoard/communityElements";

const LoanNotFound = () => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			paddingTop: 100,
		}}>
		<span
			style={{
				fontSize: 24,
				color: "##494949",
				fontWeight: 600,
			}}>
			Community Board Not Found
		</span>
		<Typography body2>What you searched was unfortunately</Typography>
		<Typography body2>not found or doesen't exist.</Typography>
	</div>
);

export default () => {
	const history = useHistory();
	const [filterList, setFilterList] = React.useState([]);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [loanPostList, setLoanPostList] = React.useState([]);
	const [isReportOpen, setIsReportOpen] = React.useState(false);
	const [isSendMessageOpen, setIsSendMessageOpen] = React.useState(false);

	const [selectedBoard, setSelectedBoard] = React.useState({
		boardId: null,
		userId: null,
	});

	const [mutateLoanPostList, { isLoading, data, isSuccess }] = useMutation(
		useLoanPostList,
		{
			onSuccess: (response) => {
				const {
					pagination: { current_page },
					loan_posts,
				} = response;
				setCurrentPage(current_page);

				if (current_page === 1) {
					setLoanPostList(
						response.loan_posts.map((d) => ({
							...d,
							myLike: d.my_like,
						}))
					);
				} else {
					setLoanPostList(
						[...loanPostList, ...loan_posts].map((d) => ({
							...d,
							myLike: d.my_like,
						}))
					);
				}
			},
		}
	);

	const [mutateLoanPostLike] = useMutation(useLoanPostLike, {
		onSuccess: () => fetchLoanPost(1),
	});

	React.useEffect(() => fetchLoanPost(1), []);

	React.useEffect(() => {
		if (data && filterList.length === 0) {
			setFilterList(data.filters);
		}
	}, [data]);

	const fetchLoanPost = (page) => {
		mutateLoanPostList({
			currentPage: page,
			filters: filterList
				.map((d) => (d.current_value ? d : undefined))
				.filter((d) => d),
		});
	};

	const handleLoadMore = () => fetchLoanPost(currentPage + 1);

	const handleLike = (id) => {
		setLoanPostList(
			loanPostList.map((d) => ({
				...d,
				myLike: d.id === id ? !d.myLike : d.myLike,
				total_like:
					d.id === id
						? d.myLike
							? d.total_like - 1
							: d.total_like + 1
						: d.total_like,
			}))
		);
		mutateLoanPostLike({
			loan_post_id: id,
		});
	};

	return (
		<>
			<ReportDialog
				open={isReportOpen}
				close={() => setIsReportOpen()}
				id={selectedBoard.boardId}
			/>
			<SendMessage
				boardId={selectedBoard.boardId}
				userId={selectedBoard.userId}
				open={isSendMessageOpen}
				close={() => setIsSendMessageOpen(false)}
			/>
			<HeroSection
				title="Community Board"
				// description="You have come to the right place to search for a Commercial Real Estate Lender. We have listings of over 2000 commercial real estate lenders"
			/>
			<Container>
				<BreadCrumbs data={[{ title: "Community Board" }]} />
				{isLoading && loanPostList.length === 0 ? (
					<ClipLoader size={28} color={colors.primary} loading={true} />
				) : (
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-between",
							paddingTop: 20,
						}}>
						{isSuccess && !loanPostList.length && <LoanNotFound />}
						{loanPostList.map(
							({
								id,
								title,
								created_at,
								image,
								description,
								user,
								total_like,
								myLike,
								total_comments,
							}) => (
								<ItemWrap key={id}>
									<div
										style={{
											cursor: "pointer",
										}}
										onClick={() => history.push(`/communityBoard/${id}`)}>
										<div style={{ display: "flex" }}>
											<img
												src={user?.image}
												style={{
													width: 50,
													height: 50,
													borderRadius: 30,
													backgroundColor: "#efefef",
													objectFit: "contain",
												}}
											/>
											<div style={{ marginLeft: 12, flex: 1 }}>
												<Typography body1>{user?.name}</Typography>
												<div>
													<Typography body2>
														{moment(created_at).format("MMMM DD, YYYY")}
													</Typography>
												</div>
											</div>
											<MoreBtn
												onClick={(e) => {
													e.stopPropagation();
													setIsReportOpen(true);
													setSelectedBoard({
														boardId: id,
														userId: user.id,
													});
												}}>
												<FiMoreHorizontal size={22} color="#555" />
											</MoreBtn>
										</div>
										<div style={{ marginTop: 20, marginBottom: 6 }}>
											<div
												style={{
													height: 26,
													overflow: "hidden",
													fontWeight: 500,
													color: "#555",
													fontSize: 18,
												}}>
												{title}
											</div>
											<div
												style={{
													height: 42,
													overflow: "hidden",
													color: "#999",
													fontSize: 14,
												}}>
												{description && ReactHtmlParser(description)}
											</div>
										</div>
										{image && (
											<img
												src={image}
												style={{
													height: 220,
													width: "100%",
													backgroundColor: "#f1f1f1",
													objectFit: "cover",
													margin: "6px 0",
												}}
											/>
										)}
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
										}}>
										<LikeCommentWrap
											onClick={(e) => {
												e.stopPropagation();
												handleLike(id);
											}}>
											<AiOutlineLike
												size={20}
												color={myLike ? colors.primary : "#444"}
											/>
											<div
												style={{
													marginLeft: 6,
													fontSize: 13,
													color: myLike ? colors.primary : "#666",
												}}>
												{total_like ? total_like : null} Like
											</div>
										</LikeCommentWrap>
										<LikeCommentWrap
											onClick={() => history.push(`/communityBoard/${id}`)}>
											<FaRegComment size={18} color="#555" />
											<div
												style={{ marginLeft: 6, fontSize: 13, color: "#666" }}>
												{total_comments > 0 && total_comments} Comment
											</div>
										</LikeCommentWrap>
										<LikeCommentWrap
											onClick={() => {
												setIsSendMessageOpen(true);
												setSelectedBoard({
													boardId: id,
													userId: user.id,
												});
											}}>
											<FiSend size={18} color="#555" />
											<div
												style={{ marginLeft: 6, fontSize: 13, color: "#666" }}>
												Send Message
											</div>
										</LikeCommentWrap>
									</div>
								</ItemWrap>
							)
						)}
						<LoadMore
							dataPerPage={data}
							allData={loanPostList}
							isLoading={isLoading}
							isSuccess={isSuccess}
							onClick={handleLoadMore}
						/>
					</div>
				)}
			</Container>
		</>
	);
};
