import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
} from "../../../components";
import useNewsDetail from "../../../hooks/useNewsDetail";
import useTopNews from "../../../hooks/useTopNews";
// import profileImage from "../../assets/profileImage.png";
import {
  NewsImage,
  NewsInfo,
  NewsInfoElement,
  InfoIcon,
  NewsContent,
  NewsContainer,
  NewsContentContainer,
  MainNewsContainer,
  SideContainer,
  SideContent,
  SideItem,
  TopNewsInfo,
  SideItemContainer,
} from "./newsDetailElements";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { MdDateRange } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";

export default () => {
  const { newsId } = useParams();
  const {
    isLoading: isLoadingNewsDetail,
    data: newsDetailData,
  } = useNewsDetail(newsId);
  const { isLoading: isLoadingTopNews, data: topNewsData } = useTopNews();

  return (
    <>
      <HeroSection
        title={newsDetailData?.title}
        description={newsDetailData?.sub_title}
      />
      <Container>
        <BreadCrumbs
          data={[
            { title: "News", pathname: "/news" },
            { title: newsDetailData?.title },
          ]}
        />
        {isLoadingNewsDetail ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <NewsContainer>
            <MainNewsContainer>
              <NewsImage img={newsDetailData?.image} />
              <NewsContentContainer>
                <Typography h3>{newsDetailData?.title}</Typography>
                <NewsInfo>
                  <InfoIcon>
                    <FaUserCheck />
                  </InfoIcon>
                  <NewsInfoElement>{newsDetailData.author}</NewsInfoElement>
                  <InfoIcon>
                    <MdDateRange />
                  </InfoIcon>
                  <NewsInfoElement>
                    {newsDetailData &&
                      moment(newsDetailData.date).format("MMMM DD, YYYY")}
                  </NewsInfoElement>
                </NewsInfo>
                <Typography h3>{newsDetailData?.name}</Typography>
                <NewsContent>
                  <Typography body1>
                    {newsDetailData && ReactHtmlParser(newsDetailData.content)}
                  </Typography>
                </NewsContent>
              </NewsContentContainer>
            </MainNewsContainer>
            <SideContainer>
              <SideContent>
                <Typography h2 fontSize="22px">
                  Top News and Articles
                </Typography>
                {isLoadingTopNews ? (
                  <Typography h3>Loading...</Typography>
                ) : (
                  <SideItemContainer>
                    {topNewsData.map(({ id, title, excerpt, date }) => (
                      <SideItem key={id}>
                        <Typography h3>
                          <Link to={`/news/${id}`}>{title}</Link>
                        </Typography>
                        <Typography body1>{excerpt}</Typography>
                        <TopNewsInfo>
                          <InfoIcon>
                            <MdDateRange />
                          </InfoIcon>
                          <NewsInfoElement>
                            {moment(date).format("MMMM DD, YYYY")}
                          </NewsInfoElement>
                        </TopNewsInfo>
                      </SideItem>
                    ))}
                  </SideItemContainer>
                )}
              </SideContent>
            </SideContainer>
          </NewsContainer>
        )}
      </Container>
    </>
  );
};
