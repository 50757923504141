import React from "react";
import {
  BreadCrumbs,
  Container,
  HeroSection,
  Typography,
  FormLabel,
  FormInput,
} from "../../components";
import {
  BreadCrumbsWrap,
  ColumnWrapper,
  LeftColumn,
  LeftColumnContent,
  RightColumn,
  FilterInputWrap,
  FilterButton,
  FilterWrap,
} from "./listingElements";
import ReactSelect from "react-select";
import ProfileList from "./ProfileList";
import { useLocation } from "react-router-dom";
import useSubCategoryList from "../../hooks/useSubCategoryList";
import { useMutation } from "react-query";
import { BsFilter } from "react-icons/bs";
import FilterDialog from "./FilterDialog";
import queryString from "query-string";
import useCategories from "../../hooks/useCategories";
import getAmount from "../../utilities/getAmount";

export default () => {
  const { search } = useLocation();
  const { type } = queryString.parse(search);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [filterList, setFilterList] = React.useState([]);
  const [profileList, setProfileList] = React.useState([]);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const { data: categoryList } = useCategories();

  const [subCategoryId, setSubCategoryId] = React.useState(null);

  const [mutateSubCategoryList, { isLoading, data, isSuccess }] = useMutation(
    useSubCategoryList,
    {
      onSuccess: (response) => {
        const {
          pagination: { current_page },
          profiles,
          filters,
        } = response;
        console.log(profiles);
        setCurrentPage(current_page);

        if (!filterList.length) {
          setFilterList(filters);
        }

        if (current_page === 1) {
          setProfileList(profiles);
        } else {
          setProfileList([...profileList, ...profiles]);
        }
      },
    }
  );

  const tempSubCategoryName = location.search
    .split("&name=")[1]
    .replaceAll("%20", " ");

  React.useEffect(() => {
    if (categoryList) {
      const tempSubCategoryId = categoryList
        .find(({ title }) => title === type)
        .subcategories.find(({ title }) => title === tempSubCategoryName).id;

      setSubCategoryId(tempSubCategoryId);
      fetchSubCategoryList(1, [], tempSubCategoryId);
    }
  }, [categoryList]);

  const fetchSubCategoryList = (page, filters, subCatId = subCategoryId) => {
    mutateSubCategoryList({
      currentPage: page,
      body: {
        category_id: categoryList.find(({ title }) => title === type).id,
        sub_category_id: subCatId,
        filters,
      },
    });
  };

  const handleChange = (selectedItem, filter_id) => {
    setFilterList(
      filterList.map((d) => ({
        ...d,
        current_value:
          d.filter_id === filter_id ? selectedItem : d.current_value,
      }))
    );
  };

  const handleApplyFilter = () => {
    window.scrollTo(0, 300);
    fetchSubCategoryList(
      1,
      filterList.map((d) => (d.current_value ? d : undefined)).filter((d) => d)
    );
  };

  const handleLoadMore = () =>
    fetchSubCategoryList(currentPage + 1, filterList);

  const getFilterList = () => {
    const loanAmount = filterList.find((d) => d.filter_title === "Loan Amount");
    const propertyValue = filterList.find(
      (d) => d.filter_title === "Property Value"
    );

    let ltv;

    if (
      loanAmount &&
      loanAmount.current_value &&
      !isNaN(loanAmount.current_value) &&
      propertyValue &&
      propertyValue.current_value &&
      !isNaN(propertyValue.current_value)
    ) {
      ltv = (
        (loanAmount.current_value / propertyValue.current_value) *
        100
      ).toFixed(2);
    } else {
      ltv = undefined;
    }

    return (
      <>
        {filterList.map(
          ({
            filter_id,
            filter_title,
            current_value,
            filter_type,
            filter_list,
          }) => {
            return (
              <FilterInputWrap key={filter_id}>
                <FormLabel>{filter_title}</FormLabel>
                <div style={{ width: "100%", marginTop: 5 }}>
                  {(filter_type === "input" ||
                    filter_type === "amount_input") && (
                    <FormInput
                      dollarSign={filter_type === "amount_input"}
                      value={
                        filter_type === "amount_input"
                          ? getAmount(current_value)
                          : current_value
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        // remove comma
                        if (filter_type === "amount_input") {
                          value = value.replace(/,/g, "");
                        }
                        handleChange(value, filter_id);
                      }}
                    />
                  )}
                  {(filter_type === "select" ||
                    filter_type === "multi_select") && (
                    <ReactSelect
                      options={filter_list}
                      value={current_value}
                      onChange={(selectedItem) =>
                        handleChange(selectedItem, filter_id)
                      }
                      isMulti={filter_type === "multi_select"}
                      isClearable
                    />
                  )}
                </div>
              </FilterInputWrap>
            );
          }
        )}
        {loanAmount && propertyValue && (
          <FilterInputWrap>
            <FormLabel>Loan To Value</FormLabel>
            <div style={{ width: "100%", marginTop: 5 }}>
              <FormInput value={ltv ? `${+ltv}% LTV` : ""} disabled />
            </div>
          </FilterInputWrap>
        )}
      </>
    );
  };

  return (
    <>
      <HeroSection
        title={type}
        description={data?.title}
        description2={data?.sub_title}
      />
      <Container>
        <BreadCrumbsWrap>
          <BreadCrumbs
            data={[
              { title: type, pathname: `/allSubCategoryList?type=${type}` },
              { title: tempSubCategoryName },
            ]}
          />
        </BreadCrumbsWrap>
        <ColumnWrapper>
          <FilterDialog
            title="Filter"
            open={isFilterOpen}
            close={() => setIsFilterOpen(false)}
          >
            <div style={{ backgroundColor: "white", width: 300 }}>
              {getFilterList()}
              <FilterButton
                onClick={() => {
                  setIsFilterOpen(false);
                  handleApplyFilter();
                }}
              >
                <span>Apply Filter</span>
              </FilterButton>
            </div>
          </FilterDialog>
          <FilterWrap>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => setIsFilterOpen(true)}
            >
              <BsFilter size={26} style={{ marginRight: 10 }} />
              <Typography body1>Filter</Typography>
            </div>
          </FilterWrap>
          <LeftColumn>
            <LeftColumnContent>
              {getFilterList()}
              <FilterButton onClick={handleApplyFilter}>
                <span>Apply Filter</span>
              </FilterButton>
            </LeftColumnContent>
          </LeftColumn>
          <RightColumn>
            <ProfileList
              isLoading={isLoading}
              isSuccess={isSuccess}
              dataPerPage={data}
              allData={profileList}
              refetch={() => fetchSubCategoryList(1, filterList)}
              handleLoadMore={handleLoadMore}
              isSubCategoryList={true}
              subCategoryName={tempSubCategoryName}
            />
          </RightColumn>
        </ColumnWrapper>
      </Container>
    </>
  );
};
