import React from "react";
import { Typography, Button, FormLabel, FormInput } from "../../components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from "react-icons/md";
import useForm from "../../hooks/useForm";
import validate from "../../utilities/validate";
import { ErrorText } from "./signInElements";
import useForgotPassword from "../../hooks/useForgotPassword";
import { useMutation } from "react-query";
import Alert from "@material-ui/lab/Alert";

const formData = [
  {
    name: "emailAddress",
    label: "Email Address",
    placeholder: "Enter Your Email Address",
    type: "email",
    value: "",
    required: true,
  },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h2>{children}</Typography>
      <Typography body1>
        Enter your email address below to reset password
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default ({ open, close }) => {
  const { values, handleChange, setValues } = useForm(formData);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [successMessage, setSuccessMessage] = React.useState("");

  const [mutateForgotPassword, { isLoading }] = useMutation(useForgotPassword, {
    onSuccess: (response) => {
      setSuccessMessage(response.data.message);
      resetFormState();
    },
    onError: (error) => {
      setErrorMessage(error.response.data.message);
    },
  });

  const resetFormState = () => {
    setValues(
      values.map((d) => ({
        ...d,
        value: "",
      }))
    );
  };

  const handleClose = () => {
    close();
    resetFormState();
    setSuccessMessage("");
    setErrorMessage("");
    setFormErrors({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});
    setErrorMessage("");
    setSuccessMessage("");

    const tempErrors = validate(values);

    if (Object.keys(tempErrors).length) {
      setFormErrors(tempErrors);
    } else {
      mutateForgotPassword({
        email: values[0].value,
      });
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Forgot Password
      </DialogTitle>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <DialogContent dividers>
        <form style={{ width: 400 }} onSubmit={handleSubmit}>
          {values.map(({ name, label, placeholder, type, value, required }) => (
            <div key={name}>
              <FormLabel required={required}>{label}</FormLabel>
              <FormInput
                name={name}
                onChange={handleChange}
                placeholder={placeholder}
                value={value}
                type={type}
              />
              {<ErrorText>{formErrors[name]}</ErrorText>}
            </div>
          ))}
          <Button marginTop={20} onClick={handleSubmit} isLoading={isLoading}>
            Send
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
