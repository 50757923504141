// import moment from "moment";
// import { API_URL } from "../constants/constants";
// import axios from "axios";

import { getLocalStorageToken } from "../utilities/setStorage";

export default async () => {
  const token = getLocalStorageToken();
  return token;

  // const { accessExpiration, accessToken, refreshToken } = localStorageData;

  // if (moment().isAfter(moment(accessExpiration))) {
  //   try {
  //     const { data } = await axios({
  //       method: "post",
  //       url: API_URL + "/auth/refresh",
  //       headers: {},
  //       data: {
  //         token: refreshToken,
  //       },
  //     });

  //     const newlocalStorageData = {
  //       ...localStorageData,
  //       accessToken,
  //       accessExpiration,
  //     };

  //     localStorage.setItem("@iqvidhi", JSON.stringify(newlocalStorageData));
  //     return data.data.accessToken;
  //   } catch (error) {
  //     console.log(error);
  //     return 123;
  //   }
  // }
  // return accessToken;
};
