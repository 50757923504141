import Api from "../api/api";
import { useQuery } from "react-query";

const getEvents = async (_, page) => {
  const { data } = await Api.get(`events?page=${page}`);
  return data.data;
};

export default (props) => {
  return useQuery(["events", props], getEvents);
};
