import React from "react";
import Typography from "../Typography/Typography";
import Button from "../Button/Button";

import { FormLabel, FormInput } from "../Form/Form";
import { FormErrorText, FormTextArea } from "../Form/FormElements";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from "react-icons/md";
import useForm from "../../hooks/useForm";
import validate from "../../utilities/validate";
import { AppContext } from "../../AppContext";
import useSendMessage from "../../hooks/useSendMessage";
import { useMutation } from "react-query";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import {
  RememberMe,
  RememberMeChecked,
  RememberMeUnChecked,
} from "../../screens/signUp/signUpElements";
import { FaCheck } from "react-icons/fa";

const formData = [
  {
    name: "firstName",
    label: "First Name",
    placeholder: "Enter your first name",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "lastName",
    label: "Last Name",
    placeholder: "Enter your last name",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    placeholder: "(123) 456-7890",
    type: "text",
    value: "",
    required: true,
  },
  {
    name: "emailAddress",
    label: "Email Address",
    placeholder: "Enter your email address",
    type: "email",
    value: "",
    required: true,
  },
  {
    name: "message",
    label: "Message",
    placeholder: "Enter message",
    type: "textarea",
    value: "",
    required: true,
  },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h3>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default ({ open, close, userId }) => {
  const { values, handleChange, setValues } = useForm(formData);
  const [formErrors, setFormErrors] = React.useState({});
  const { currentUser } = React.useContext(AppContext);
  const [isAgree, setIsAgree] = React.useState(false);
  const [isMessageOpen, setIsMessageOpen] = React.useState(false);

  const [mutateSendMessage, { isLoading }] = useMutation(useSendMessage, {
    onSuccess: (response) => {
      setSuccessMessage(response.message);
      setValues(
        values.map((d) => ({
          ...d,
          value: d.name === "message" ? "" : d.value,
        }))
      );
      setIsMessageOpen(true);
    },
  });

  const [successMessage, setSuccessMessage] = React.useState("");

  React.useEffect(() => {
    if (currentUser) {
      const { first_name, last_name, email, phone_no } = currentUser;

      const tempProfile = formData.map((d) => {
        let value;
        if (d.name === "firstName") {
          value = first_name ? first_name : "";
        } else if (d.name === "lastName") {
          value = last_name ? last_name : "";
        } else if (d.name === "phoneNumber") {
          value = phone_no ? phone_no : "";
        } else if (d.name === "emailAddress") {
          value = email ? email : "";
        } else if (d.name === "message") {
          value = "";
        }
        return { ...d, value };
      });

      setValues(tempProfile);
    }
  }, [currentUser]);

  const message = values.find((d) => d.name === "message");

  const handleSend = (e) => {
    e.preventDefault();
    setFormErrors({});
    setSuccessMessage("");

    const tempErrors = validate(values);
    if (Object.keys(tempErrors).length > 0) {
      setFormErrors(tempErrors);
    } else {
      const first_name = values.find((d) => d.name === "firstName").value;
      const last_name = values.find((d) => d.name === "lastName").value;
      const phone_no = values.find((d) => d.name === "phoneNumber").value;
      const email = values.find((d) => d.name === "emailAddress").value;
      const message = values.find((d) => d.name === "message").value;

      close();
      mutateSendMessage({
        user_id: userId,
        first_name,
        last_name,
        phone_no,
        email,
        message,
      });
    }
  };

  const handleClose = () => {
    close();
    setFormErrors({});
    setSuccessMessage("");
  };

  const toggleAgree = () => setIsAgree(!isAgree);

  return (
    <>
      <Dialog
        onClose={() => setIsMessageOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isMessageOpen}
        maxWidth={false}
      >
        <div
          style={{
            width: 300,
            padding: 20,
          }}
        >
          <Alert severity="success">{successMessage}</Alert>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              cancel
              marginTop={20}
              onClick={() => setIsMessageOpen(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={false}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Contact This Professional
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSend}>
            <div
              style={
                {
                  // display: "flex",
                  // flexWrap: "wrap",
                  // maxWidth: 800,
                  // justifyContent: "space-between",
                }
              }
            >
              {values.map(
                ({ name, label, placeholder, type, value, required }) =>
                  (type === "text" || type === "email") && (
                    <div key={name} style={{ paddingBottom: 10 }}>
                      <FormLabel htmlFor={name} required={required}>
                        {label}
                      </FormLabel>
                      <FormInput
                        id={name}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={handleChange}
                      />
                      {<FormErrorText>{formErrors[name]}</FormErrorText>}
                    </div>
                  )
              )}
            </div>
            <div>
              <FormLabel htmlFor={message.name} required={message.required}>
                Message
              </FormLabel>
              <FormTextArea
                id={message.name}
                name={message.name}
                rows={6}
                value={message.value}
                onChange={handleChange}
              />
              {<FormErrorText>{formErrors[message.name]}</FormErrorText>}
            </div>
            <div style={{ flex: 1 }}>
              <RememberMe>
                {isAgree ? (
                  <RememberMeChecked onClick={toggleAgree}>
                    <FaCheck />
                  </RememberMeChecked>
                ) : (
                  <RememberMeUnChecked onClick={toggleAgree} />
                )}
                <div style={{ flex: 1 }}>
                  <Typography body1>
                    I acknowledge and agree to Big Hound&nbsp;
                    <Link
                      to="/termsAndConditions"
                      target="_blank"
                      style={{ fontWeight: 500 }}
                    >
                      Terms & Conditions
                    </Link>{" "}
                    and &nbsp;
                    <Link
                      to="/privacyPolicy"
                      target="_blank"
                      style={{ fontWeight: 500 }}
                    >
                      Privacy Policy.
                    </Link>
                  </Typography>
                </div>
              </RememberMe>
            </div>
            <Button
              type="submit"
              marginTop={20}
              isLoading={isLoading}
              disabled={!isAgree}
            >
              Send
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
