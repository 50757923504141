import React from "react";
import {
  NavBtnWrap,
  ProfilePicture,
  NavProfileWrap,
  ProfileDropDownItem,
  ProfileDDItemText,
} from "./NavbarElements";
import defaultProfilePicture from "../../assets/defaultProfilePicture.png";
import { useHistory } from "react-router-dom";
import Confirm from "../Confirm/Confirm";
import { AppContext } from "../../AppContext";
import firebase from "firebase/app";
import "firebase/auth";
import Notification from "./Notification";

export default () => {
  const history = useHistory();
  const [isLogoutOpen, setIsLogoutOpen] = React.useState(false);
  const { currentUser, setCurrentUser } = React.useContext(AppContext);

  const navigateTo = (profileType) => {
    history.push(`/profile/${profileType}`);
  };

  const handleLogout = () => {
    setIsLogoutOpen(false);
    localStorage.clear();
    setCurrentUser(null);
    firebase.auth().signOut();
    history.push("/");
  };

  return (
    <NavProfileWrap>
      <Confirm
        open={isLogoutOpen}
        close={() => setIsLogoutOpen(false)}
        confirmTitle="Logout"
        handleConfirm={handleLogout}
        title="Logout"
        description="Are you sure you want to logout?"
      />
      <div style={{ display: "flex", height: "100%", paddingRight: 5 }}>
        <Notification />
        <NavBtnWrap onClick={() => navigateTo("basic")}>
          <ProfilePicture
            src={currentUser.image ? currentUser.image : defaultProfilePicture}
          />
          <div className="profile-dropdown-menu-wrap">
            <div>
              <p style={{ color: "#494949", fontSize: 14, fontWeight: 500 }}>
                {currentUser.first_name}
              </p>
            </div>
            <div className="profile-dropdown-menu">
              <ProfileDropDownItem
                onClick={(e) => {
                  e.stopPropagation();
                  navigateTo("basic");
                }}
              >
                <ProfileDDItemText>Basic Profile</ProfileDDItemText>
              </ProfileDropDownItem>
              <ProfileDropDownItem
                onClick={(e) => {
                  e.stopPropagation();
                  navigateTo("business");
                }}
              >
                <ProfileDDItemText>Business Profile</ProfileDDItemText>
              </ProfileDropDownItem>
              <ProfileDropDownItem
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("communityBoard");
                }}
              >
                <ProfileDDItemText>Community Board</ProfileDDItemText>
              </ProfileDropDownItem>
              <ProfileDropDownItem
                onClick={(e) => {
                  e.stopPropagation();
                  navigateTo("favorite");
                }}
              >
                <ProfileDDItemText>My Favorites</ProfileDDItemText>
              </ProfileDropDownItem>
              <ProfileDropDownItem
                onClick={(e) => {
                  e.stopPropagation();
                  navigateTo("message");
                }}
              >
                <ProfileDDItemText>My Messages</ProfileDDItemText>
              </ProfileDropDownItem>
              <ProfileDropDownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setIsLogoutOpen(true);
                }}
              >
                <ProfileDDItemText>Log Out</ProfileDDItemText>
              </ProfileDropDownItem>
            </div>
          </div>
        </NavBtnWrap>
      </div>
    </NavProfileWrap>
  );
};
