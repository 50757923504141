import Api from "../api/api";
import { useQuery } from "react-query";

const getUserSettings = async () => {
  const { data } = await Api.get("user-settings");
  return data.data;
};

export default () => {
  return useQuery("userSettings", getUserSettings);
};
